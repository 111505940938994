
import React from "react"
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

interface IProps{
    onChange?: (value: string) => void
    value?: string
}

interface IState {
    initial: boolean
}

const modules = {
        toolbar: [
            [{ "font": [] }, { "size": ["small", false, "large", "huge"] }], // custom dropdown

            ["bold", "italic", "underline", "strike"],

            [{ "color": [] }, { "background": [] }],

            [{ "script": "sub" }, { "script": "super" }],

            [{ "header": 1 }, { "header": 2 }, "blockquote", "code-block"],

            [{ "list": "ordered" }, { "list": "bullet" }, { "indent": "-1" }, { "indent": "+1" }],

            [{ "direction": "rtl" }, { "align": [] }],

            ["link", "image", "video", "formula"],

            ["clean"]
        ]
    }

export default class Wysiwyg extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props)
        this.state = {
            initial: true
        }
    }

    render() {
        const {value, onChange} = this.props

        return (
           <ReactQuill modules={modules} theme={'snow'} value={value}
                       onChange={(content) => {
                           if (this.state.initial){
                               return this.setState({initial: false})
                           }
                           onChange?.(content)
                       }}/>
        )
    }
}