import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {SETUP_LOAD, SETUP_LOGIN, SETUP_LOGOUT, SETUP_RELOAD,} from '../constants/Setup';
import {endLoading, reLoad, save, startLoading, update} from "../actions/Setup";
import SetupService, {ISetup} from "../../api/service/SetupService";
import TokenService from "../../api/service/security/TokenService";

export function* setupLoad() {
    yield takeEvery(SETUP_LOAD, function* () {
        try {
            yield put(startLoading());
            const response: Promise<ISetup> = yield call(SetupService.list)
            yield put(save(response));
        } catch (error) {
            yield put(endLoading());
        }

        yield put(endLoading());
    });
}

export function* setupReload() {
    yield takeEvery(SETUP_RELOAD, function* () {
        yield put(startLoading());
        const response: ISetup = yield call(SetupService.list)
        yield put(save(response));
        yield put(endLoading());
    });
}

export function* login() {
    yield takeEvery(SETUP_LOGIN, function* (args: any) {
        yield put(update({token: args.token}));
        yield put(reLoad());
    });
}

export function* logout() {
    yield takeEvery(SETUP_LOGOUT, function* () {
        yield call(TokenService.remove)
        yield put(update({token: undefined}));
        yield put(reLoad());
    });
}

export default function* rootSaga() {
    yield all([
        fork(setupLoad),
        fork(setupReload),
        fork(login),
        fork(logout)
    ]);
}
