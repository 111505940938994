import {UI_LANGUAGE} from "../constants/Ui";


export type IUiState = {
    language: string
}

type IAction = {
    type: string,
    [x: string]: any
}

const initState: IUiState = {
    language: 'en'
}

const ui = (state = initState, action: IAction) => {
    switch (action.type) {
        case UI_LANGUAGE: {
            return {
                ...state,
                language: action.value
            }
        }
        default:
            return state;
    }
}

export default ui