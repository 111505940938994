import VivaIcons from "../../../shared/VivaIcons";
import {Collapse, Image} from "antd";
import React, {useState} from "react";
import EditableImage from "./component/EditableImage";
import EditableText from "./component/EditableText";

const Technologies = () => {

    const [activeCollapseTab, setActiveCollapseTab] = useState<string>();

    const cornerStoneImages = [
        {url: '/img/zdravydom-dreveny-zaklad.jpg', alt: 'wood'},
        {url: '/img/zdravydom-zelezny-zaklad.jpg', alt: 'metal'},
        {url: '/img/zdravydom-betonovy-zaklad.jpg', alt: 'concrete'}
    ]

    const structureImages = [
        {url: '/img/Zlozenie-podlahy-domov.jpg', alt: 'floor'},
        {url: '/img/Zlozenie-stien-domov.jpg', alt: 'wall'},
        {url: '/img/Zlozenie-strechy-domov.jpg', alt: 'roof'}
    ]

    return <div>
        <EditableImage className={'h-100'} id={'technologies.cover'} editButtonPosition={{top: 110, right: 20}}
                       fallbackUrl={'img/dom.jpg'}
                       imageRender={(url: string) => (
                           <div className="top-banner big light vindow-height" style={{
                               background: 'url(' + url + ')',
                               backgroundPosition: 'center',
                               backgroundSize: 'cover'
                           }}>
                               <span className="overlay"></span>
                               <div className="content">
                                   <h1 className="title">Technologie</h1>
                               </div>
                           </div>
                       )}/>
        <div className="container padd-only-xs margin-xs-20t margin-md-0t">
            <div className="row">
                <div className="col-xs-12 padd-only-xs">
                    <div className="services-detailed">
                        <div className="post-content">
                            <h3 className={'text-center'}>Rámová konstrukce</h3>
                            <h3 className={'text-center'}>Dřevěná technika</h3>
                            <div className={'position-relative'}>
                                <EditableText id={'technologies.description'} inputType={'wysiwyg'} fallback={
                                    <>
                                        <p>
                                            Základ dřevěné rámové konstrukce tvoří obnovitelné suroviny, které šetří použití
                                            konečných zdrojů. Díky vícevrstvé struktuře jednotlivých komponentů umožňuje
                                            dřevěná
                                            rámová konstrukce optimální poměr nákladů a přínosů. Každá součást je tedy
                                            optimálně
                                            navržena a použita ke svému účelu. To šetří nejen čas, peníze a zdroje, ale také
                                            prostorově úsporný a svědomitý způsob stavění.
                                            Sériová výroba ve výrobních zařízeních nezávislých na počasí umožňuje udržovat
                                            příznivou
                                            cenovou hladinu při stálé kvalitě.
                                        </p>
                                        <p>Stěnové, stropní a podlahové desky jsou navzájem pozitivně spojeny a vytvářejí
                                            tak
                                            zpevněnou strukturu, základ každého domu v dřevěné rámové konstrukci.
                                            Paropropustná
                                            konstrukce moderní dřevěné rámové konstrukce je dnes považována za standard na
                                            celém
                                            světě.</p>
                                        <p>
                                            Tato prodyšná konstrukce nejenže vytváří obzvlášť příjemné klima v místnosti,
                                            ale
                                            také
                                            působí proti tvorbě vlhkosti. Výztužný panel na bázi dřeva (OSB) je uspořádán na
                                            vnitřní
                                            straně nosného dřevěného rámu, takže přebírá jednak nosnou úlohu, jednak funkci
                                            parotěsné a vzduchotěsné vrstvy. To je jediný způsob, jak se zbavit vnitřní
                                            parotěsné
                                            fólie a zachovat přírodní charakter
                                            konstrukce z čistého dřeva.
                                        </p>
                                        <p>Toto uspořádání znamená, že nedochází ke kondenzaci a vytváří se vysoký stupeň
                                            sušící
                                            kapacity, což zase zvyšuje příjemné klima v místnosti.</p>
                                    </>
                                }/>
                            </div>
                        </div>

                        <div data-unique-key="0bf3c8aa9017e52dd041b7e2c3327621" className="js-load-more"
                             data-start-page="1" data-max-page="2">
                            <div
                                className="d-flex row prague_services prague_count_col3 prague_gap_col15 no-footer-content prague-load-wrapper"
                                data-columns="prague_count_col3" data-gap="prague_gap_col15">
                                <div className="portfolio-item-wrapp prague_filter_class">
                                    <div className="portfolio-item">
                                        <div className="prague-services-wrapper">
                                            {VivaIcons.smile}
                                            <h3 className="services-item-title font-size-24">Rychlost</h3>
                                            <div className="services-item-description">
                                                <h3 className={'font-size-24 font-weight-normal'}>Konstrukce rámu</h3>
                                            </div>

                                            <EditableText id={'technologies.pros.speed'} wrap={true} inputType={'wysiwyg'} fallback={
                                                <a> Výroba domu trvá 5-6 týdnů, je-li v dílně volné místo. Instalace
                                                    domu na
                                                    pozemku klienta na připravený základ trvá pouze jeden den a můžete
                                                    hned
                                                    bydlet. </a>
                                            }/>
                                        </div>
                                    </div>
                                </div>
                                <div className="portfolio-item-wrapp prague_filter_class">
                                    <div className="portfolio-item">
                                        <div className="prague-services-wrapper">
                                            {VivaIcons.bulb}
                                            <h3 className="services-item-title font-size-24">Energetická</h3>
                                            <div className="services-item-description">
                                                <h3 className={'font-size-24 font-weight-normal'}>Účinnost</h3>
                                            </div>

                                            <EditableText id={'technologies.pros.efficiency'} wrap={true} inputType={'wysiwyg'} fallback={
                                                <a>   Stěny domu jsou izolovány mineralnou vatou, jejichž hlavní
                                                    charakteristiky
                                                    jsou: nízká tepelná vodivost, hydrofobní (vodoodpudivá) vlastnost,
                                                    vysoká paropropustnost, nehořlavost, tepelné a hlukové izolační
                                                    vlastnosti, odolnost proti zatížení, hustota. Koeficient odolnosti proti
                                                    prostupu tepla R = 3,62 m.kv/W (tloušťka 150 mm), R = 5 m.kv/W (tloušťka
                                                    200 mm) se standardem
                                                    nejméně 2,8 m.kv/W, což zvyšuje energetickou účinnost doma a snižuje
                                                    náklady na topení až o 50% </a>
                                            }/>
                                        </div>
                                    </div>
                                </div>
                                <div className="portfolio-item-wrapp prague_filter_class p_f_f9e81a7 column_paralax">
                                    <div className="portfolio-item">
                                        <div className="prague-services-wrapper">
                                            {VivaIcons.hand}
                                            <h3 style={{fontSize: "24px"}}
                                                className="services-item-title">Ohleduplnost</h3>
                                            <div className="services-item-description">
                                                <h3 className={'font-size-24 font-weight-normal'}>
                                                    K životnímu prostředí
                                                </h3>
                                            </div>
                                            <EditableText id={'technologies.pros.eco'} wrap={true} inputType={'wysiwyg'} fallback={
                                                <a>Dům je vyroben z materiálů šetrných k životnímu prostředí: jehličnaté
                                                    dřevo, minerální izolace, imitace dřeva (umělé dřevo/blok), které
                                                    odpovídají normě E1.</a>
                                            }/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <h3 style={{marginBottom: "25px"}}>Často kladené dotazy</h3>
                        <Collapse style={{padding: 0}} defaultActiveKey={['1']} accordion={true}
                                  onChange={key => setActiveCollapseTab(Array.isArray(key) ? key[0] : key)}
                                  ghost items={[
                            {
                                label: 'Jakým materiálem jsou izolovány stěny, strop a podlahy?',
                                text: <>
                                    <p>Základní sada obsahuje tloušťku čedičové minerální vaty ve stěnách – 15 cm,
                                        podlaze a stropě minerální vlna 20 cm.</p>
                                    <p>Hydro a parozábrana na vnitřní a vnější straně.</p>
                                    <p>Díky této technologii výroby jsou výsledkem modulové stavby a domy na klíč, které
                                        splňují vysoké nároky našich zákazníků</p>
                                    {/*
                                     <!-- <p>Kamenná - čedičová vata, má vynikající tepelně-izolační i zvukově-izolační vlastnosti, je prodyšná (dýchá), odolná vůči požáru, vlhkosti, bakterii a houbám, zdravotně nezávadná a ekologická. Tyto vlastnosti ji spolu s dlouhým životním cyklem předurčují pro aplikace s vyššími nároky na kvalitu nebo bezpečnost materiálů.</p> -->
                                    */}</>
                            },
                            {
                                label: 'Jakou má dům energetickou účinnost?',
                                text: <p>
                                    Stěny domu jsou izolovány čedičovou minerální vlnou, jejíž hlavní
                                    charakteristiky jsou: nízká tepelná vodivost, hydrofobní (vodoodpudivá)
                                    vlastnost, vysoká paropropustnost, nehořlavost, tepelné a hlukové izolační
                                    vlastnosti, odolnost proti zatížení, hustota. Součinitel odolnosti proti
                                    prostupu tepla R = 3,62 m.kv/W (tloušťka 150 mm), R = 5 m.kv/W (tloušťka 200 mm)
                                    se standardem nejméně
                                    2,8 m.kv/W, což zvyšuje energetickou účinnost doma a snížit náklady na topení až
                                    o 50%.
                                </p>
                            },
                            {
                                label: 'Jaká je životnost mobilního domu?',
                                text: <p>Z hlediska životnosti není modulární dům v žádném případě horší než tradiční
                                    výstavba. Zpravidla platí, že montovaný dům vydrží za předpokladu jeho
                                    pravidelné údržby celý život. Životnost modulární výstavby je stejná jako u
                                    montovaných domů a pohybuje se mezi 45 a 100 lety. Říká se, že existují i velmi
                                    staré domy, které již tuto hranici překročily. 😉</p>
                            },
                            {
                                label: 'Jaká připojení dům vyžaduje?',
                                text: <>
                                    <p>Dům vyžaduje připojení na tři komunikace.</p>

                                    <p>První je elektřina. Na vnější stěně domu je nainstalována deska na měření (každý
                                        region má svou vlastní úpravu měřiče) se vstupním bezpečnostním spínačem.</p>

                                    <p>
                                        Druhou a třetí je voda a kanalizace. Dům má vlastní vnitřní odtok a kanalizaci.
                                        Voda je napojena na kuchyňský dřez, kotel, WC, sprchový kout, jsou zobrazeny
                                        připojovací body pro pračku a umyvadlo. Přívod vody se provádí polyethylenovým
                                        potrubím o průměru 25 mm, které musí být připojeno k čerpadlu ponořenému do
                                        studny nebo k centrálnímu přívodu vody (v teplé sezóně je možné
                                        připojit se k externí nádrži). Odtok se provádí potrubím o průměru 110 mm do
                                        centrálního kanalizačního systému nebo do předem připraveného septiku (2
                                        betonové kroužky / plastová nádoba).
                                    </p>
                                </>
                            },
                            {
                                label: 'Jaké je topení v domě?',
                                text: <p>V základní konfiguraci domu je elektrický topný systém. Instalované zásuvky pod
                                    okny pro elektrické konvektory a vyvedené na samostatný stroj. Dále je možné za
                                    doplatek provést přípravné práce pro instalaci podlahového infra vytápění,
                                    topidla, krbu nebo kotle na tuhé palivo.</p>
                            },
                            {
                                label: 'Jak se zpracovávají materiály?',
                                text: <>
                                    <p>Rám domu je ošetřen přírodním antiseptikem na rostlinné bázi, které chrání dřevo
                                        před hnilobou, spálením a škůdci. Uvnitř domu se používají vodové barvy, zatímco
                                        venku je dřevo ošetřeno speciální fasádní barvou s UV filtrem. K tomu má výroba
                                        speciální koupel, proto se zpracování provádí ponořením dřeva do roztoku, jak to
                                        vyžaduje technologie.</p>
                                    <p>Vždy se snažíme o přirozené a udržitelné řešení.</p>
                                </>
                            },
                            {
                                label: 'Existují pokyny a diagram základů?',
                                text: <p>Na základ máme dva požadavky. Dům musí být větraný zdola, to znamená, že
                                    vzdálenost od domu k zemi musí být minimálně 20 cm a základ musí být vyrovnán ve
                                    stejné rovině. Naše moduly jsou umístěny na základových pásech, ale jsou možné i
                                    jiné základy, např. betonové patky, zemní vruty.</p>
                            }

                        ].map((item, index) => ({
                            key: 'tab_' + index,
                            showArrow: false,
                            label: <div className="woocommerce-tabs wc-tabs-wrapper">
                                <div
                                    className={"tabs-item-wrapp " + (activeCollapseTab === 'tab_' + index ? 'active' : '')}>
                                    <div className="description_tab tabs-title" id="tab-title-description"
                                         aria-controls="tab-description">
                                        <span className="tabs-mark">
                                            <span className="tabs-mark-plus">+</span>
                                            <span className="tabs-mark-minus">-</span>
                                        </span>
                                        {item.label}
                                    </div>
                                </div>
                            </div>
                            ,
                            children: <div
                                className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab shadow-0"
                                id="tab-description" aria-labelledby="tab-title-description " style={{margin: "15px"}}>
                                {item.text}
                            </div>
                        }))}>
                        </Collapse>

                        <Image.PreviewGroup preview={{toolbarRender: () => <div></div>}}>
                            <h3 style={{margin: "25px"}}>Složení modulárních domů</h3>

                            <div id="gallery-1"
                                 className="gallery galleryid-915 gallery-columns-3 gallery-size-full flex">
                                {structureImages.map((image, i) => (
                                    <figure key={i} className="gallery-item">
                                        <div className="gallery-icon landscape">
                                            <EditableImage editButtonPosition={{top: 5, right: 5}}
                                                           id={'technologies.materials.' + i}
                                                           fallbackUrl={image.url}
                                                           imageRender={(url: string) => (
                                                               <Image src={url} preview={{mask: false}}
                                                                      className="attachment-full size-full cursor-pointer"
                                                                      alt={image.alt}
                                                               />
                                                           )}/>
                                        </div>
                                    </figure>
                                ))}
                            </div>

                            <h3 style={{margin: "25px"}}>Typy základů</h3>
                            <div id="gallery-1"
                                 className="gallery galleryid-915 gallery-columns-3 gallery-size-full flex">
                                {cornerStoneImages.map((image, i) => (
                                    <figure key={i} className="gallery-item">
                                        <div className="gallery-icon landscape">
                                            <div className={'position-relative'}>
                                                <EditableImage editButtonPosition={{top: 5, right: 5}}
                                                               id={'technologies.foundation.' + i}
                                                               fallbackUrl={image.url}
                                                               imageRender={(url: string) => (
                                                                   <Image src={url} preview={{mask: false}}
                                                                          fallback={image.url}
                                                                          className="attachment-full size-full cursor-pointer"
                                                                          alt={image.alt}
                                                                   />
                                                               )}/>
                                                <div className={'position-absolute p-1 w-100'} style={{background: 'rgba(255, 255, 255, 0.8)', bottom: 0, right: 0, zIndex: 1}}>
                                                    <EditableText id={'technologies.foundation.type.' + i} inputType={'basic'} fallback={'text'} editButtonPosition={{right: 1, top: -10}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </figure>
                                ))}
                            </div>
                        </Image.PreviewGroup>

                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Technologies