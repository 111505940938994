import {Alert, Button, Col, Form, Input, InputNumber, Modal, Row, Tooltip} from "antd";
import {DragOutlined, EyeInvisibleOutlined, EyeOutlined, HomeOutlined, SaveOutlined, PushpinOutlined} from '@ant-design/icons'
import React, {FC, useState} from "react";
import {IModularHouse} from "../../../../api/entity/home/IModularHouse";
import Wysiwyg from "../../../../shared/wysiwyg/Wysiwyg";
import FileUpload from "../../../../shared/input/FileUpload";
import FileService from "../../../../api/service/FileService";
import ModularHouseService from "../../../../api/service/home/ModularHouseService";
import {decimalSeparator, uuid} from "../../../../shared/Utils";
import {UploadFileStatus} from "antd/es/upload/interface";
import {IFile} from "../../../../api/entity/file/IFile";
import {IStore} from "../../../../redux/IStore";
import {connect} from "react-redux";

interface IProps {
    house: IModularHouse
    onFinish: (house?: IModularHouse) => void
    modularHouses: IModularHouse[]
}

const ModularForm: FC<IProps> = ({house, onFinish}) => {

    const [form] = Form.useForm();

    const [saving, setSaving] = useState(false);
    const [onHomepage, setOnHomepage] = useState(house.onHomepage);
    const [isPublic, setPublic] = useState(house.isPublic === undefined ? true : house.isPublic);
    const [isNew, setNew] = useState(house.isNew === undefined ? false : house.isNew);


    const onSubmit = async (values?: IModularHouse) => {
        let files: IFile[] = []
        setSaving(true)
        const newImages = values?.images.filter(i => !house.images.find(image => image.uid === i.uid)) || []
        if (newImages.length) {
            const formData = new FormData()
            newImages.forEach(image => {
                if (image.originFileObj) {
                    formData.append('files[]', image.originFileObj, image.name)
                }
            })
            await FileService.upload?.(formData).then(results => files = results)
        }

        const data = {
            ...house, ...values,
            isPublic,
            onHomepage : isPublic && onHomepage,
            isNew : isPublic && isNew,
            images: values?.images.map(image => {
                const uploaded = files.find(f => f.label === image.name)
                return {
                    ...(uploaded ? {
                        name: uploaded.label,
                        uid: uploaded.id,
                        status: 'done' as UploadFileStatus,
                        type: uploaded.type
                    } : image)
                }
            }) || []
        }

        ModularHouseService.create({
            ...data,
            id: house.id || uuid()
        }).then((result: IModularHouse) => {
            onFinish(result)
            setSaving(false)
        })
    }

    return <Modal title={'Upravit'} width={800} open={true} onCancel={() => onFinish()}
                  styles={{body: {maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden'}}}
                  classNames={{footer: 'border-top'}}
                  footer={[
                      <Row key={'row'} justify={"space-between"} className={'mt-2'}>
                          <Col>
                              <Tooltip title={onHomepage ? 'Odebrat z domovské stránky' : 'Zobrazit na domovské stránce'}>
                                  <Button icon={<HomeOutlined/>} type={onHomepage ? 'primary' : undefined} disabled={!isPublic}
                                          onClick={() => setOnHomepage(!onHomepage)} className={'mr-2 border-top'}></Button>
                              </Tooltip>
                              <Tooltip title={isPublic ? 'Skrýt tento dům' : 'Zveřejnit tento dům'}>
                                  <Button icon={isPublic ? <EyeOutlined/> :
                                      <EyeInvisibleOutlined/>} type={isPublic ? 'primary' : undefined}
                                          onClick={() => setPublic(!isPublic)} className={'mr-2 border-top'}/>
                              </Tooltip>
                              <Tooltip title={isNew ? 'Vyjmout z novinek' : 'Nastavit jako novinku'}>
                                  <Button icon={isNew ? <PushpinOutlined/> :
                                      <PushpinOutlined/>} type={isNew ? 'primary' : undefined}
                                          onClick={() => setNew(!isNew)}/>
                              </Tooltip>
                          </Col>
                          <Col>
                              <Button onClick={() => onFinish()} className={'mr-2'}>Zrušiť</Button>
                              <Button onClick={() => form.validateFields().then(onSubmit)} type={"primary"}
                                      icon={<SaveOutlined/>} loading={saving}>Uložiť</Button>
                          </Col>
                      </Row>
                  ]}>
        <Form layout={"vertical"} form={form} onFinish={onFinish} initialValues={{...house}} className={'w-100'}>
            <Row className={'mt-2 w-100 p-1'}>
                <Col>
                    <Form.Item label={'Název'} name={'name'} className={'mb-1'}
                               rules={[{required: true, message: 'Políčko je vyžadováno'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Cena'} name={'price'}>
                        <InputNumber className={'w-100'} addonAfter={'Kč'} formatter={value => value ? Number(value).toLocaleString() : ''} parser={value => {
                            const replace = `[^\\d\\${decimalSeparator()}]`;
                            const regExp = new RegExp(replace, "g");
                            return value ?
                                value.replace(regExp, '').replaceAll(',', '.') : ''
                        }}/>
                    </Form.Item>
                    <Form.Item label={'Střecha'} name={'roof'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Rám'} name={'frame'}  className={'mb-1'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Topné zařízení'} name={'heating'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Izolace'} name={'isolation'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Zapojení'} name={'wiring'} initialValue={'měď'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Podlahové krytiny'} name={'floor'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Podlahové krytiny v koupelně'} name={'bathroomFloor'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Okna'} name={'window'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Dveře'} name={'door'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Vybavení domu'} name={'equipment'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Dekorace vnější stěny'} name={'outerWallDecoration'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Dekorace vnitřní stěny a stropu'} name={'innerWallDecoration'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Další poznámky'} name={'note'}>
                        <Wysiwyg/>
                    </Form.Item>
                    <Form.Item label={'Výměra'} name={'area'}>
                        <InputNumber className={'w-100'} addonAfter={'m2'}/>
                    </Form.Item>
                    <Form.Item label={'Vnější'}>
                        <Row gutter={[10, 10]} wrap={false}>
                            <Col>
                                <Form.Item noStyle={true} name={'outerHeight'}>
                                    <InputNumber/>
                                </Form.Item>
                            </Col>
                            <Col>x</Col>
                            <Col>
                                <Form.Item noStyle={true} name={'outerWidth'}>
                                    <InputNumber/>
                                </Form.Item>
                            </Col>
                            <Col>x</Col>
                            <Col>
                                <Form.Item noStyle={true} name={'outerLength'}>
                                    <InputNumber/>
                                </Form.Item>
                            </Col>
                            <Col>m</Col>
                        </Row>
                    </Form.Item>
                    <Form.Item label={'Vnitřní'}>
                        <Row gutter={[10, 10]} wrap={false}>
                            <Col>
                                <Form.Item noStyle={true} name={'innerHeight'}>
                                    <InputNumber/>
                                </Form.Item>
                            </Col>
                            <Col>x</Col>
                            <Col>
                                <Form.Item noStyle={true} name={'innerWidth'}>
                                    <InputNumber/>
                                </Form.Item>
                            </Col>
                            <Col>x</Col>
                            <Col>
                                <Form.Item noStyle={true} name={'innerLength'}>
                                    <InputNumber/>
                                </Form.Item>
                            </Col>
                            <Col>m</Col>
                        </Row>
                    </Form.Item>
                    <Form.Item label={'Obrázky'} name={'images'}>
                        <FileUpload multiple={true} accept={['image/*']}/>
                    </Form.Item>
                    <Alert className={'py-2'} description={<div className={'f-small'}>
                        První obrázek je vždy profilový obrázek <br/>
                        Řadit můžete přetažením pomocí tlačítka s ikonou <DragOutlined/>
                    </div>}/>
                </Col>
            </Row>
        </Form>
    </Modal>
}

const mapStateToProps = ({setup}: IStore) => {
    const {modularHouses} = setup
    return {modularHouses}
}


export default connect(mapStateToProps)(ModularForm);