import React, {FC, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Button, Col, Collapse, Image, Row} from "antd";
import {IStore} from "../../../../redux/IStore";
import {connect} from "react-redux";
import {ISetupStateUpdate} from "../../../../redux/reducers/Setup";
import {update} from "../../../../redux/actions/Setup";
import {IUser, ROLES} from "../../../../api/entity/security/IUser";
import {EditOutlined} from "@ant-design/icons";
import FileService from "../../../../api/service/FileService";
import {ISipHouse} from "../../../../api/entity/home/ISipHouse";
import SipForm from "../form/SipForm";
import {FilePdfOutlined} from '@ant-design/icons'

interface IProps {
    sipHouses: ISipHouse[],
    update: (changes: ISetupStateUpdate) => void,
    user?: IUser
}

const SipDetail: FC<IProps> = ({sipHouses, user, update}) => {
    const params = useParams();
    const [house, setHouse] = useState<ISipHouse>();
    const [editHouse, setEditHouse] = useState<ISipHouse>();
    const [animateButton, setAnimateButton] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => setAnimateButton(true), 500)
    }, [sipHouses]);

    const onFinish = (house?: ISipHouse) => {
        setEditHouse(undefined)
        if (house) {
            const houses = [...sipHouses]
            const index = houses.findIndex(h => h.id === house?.id)
            if (index > -1) {
                houses[index] = house
            } else {
                houses.push(house)
            }
            update({sipHouses: houses})
        }
    }

    useEffect(() => {
        const name = params['house']
        const house = sipHouses.find(h => h?.name.replaceAll(' ', '_') === name)
        if (house) {
            setHouse(house)
        } else if (sipHouses.length) {
            navigate('/not-found')
        }
    }, [sipHouses]);

    return house ? <div>
        {!!editHouse && (
            <SipForm onFinish={onFinish} house={editHouse}/>
        )}
        <div
            className="shop-container container padd-only-xs product-template-default single single-product postid-2072 woocommerce woocommerce-page woocommerce-no-js wpb-js-composer js-comp-ver-5.6 responsive">
            <div className="row">
                <div className="col-sm-12 col-md-12 margin-lg-90b margin-sm-60b">
                    <div className="woocommerce-notices-wrapper"></div>
                    <div id={house.name}
                         className="post-2072 product type-product status-publish has-post-thumbnail product_cat-acssessories product_tag-casual product_tag-modern first instock virtual purchasable product-type-simple">
                        <div className="flex-item ">
                            <div
                                className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-3 images"
                                data-columns="3">
                                <Image.PreviewGroup preview={{toolbarRender: () => <div></div>}}>
                                    <div className="flex-viewport">
                                        <figure className="woocommerce-product-gallery__wrapper">
                                            <div className="woocommerce-product-gallery__image flex-active-slide">
                                                <Image src={FileService.preview(house.images[0]?.uid)} preview={{mask: false}}
                                                       draggable={false}
                                                       className="attachment-shop_single size-shop_single firstImg cursor-pointer"
                                                />
                                            </div>
                                        </figure>
                                    </div>

                                    <ol className="flex-control-nav flex-control-thumbs">
                                        {house.images.map((image, i) => (
                                            <li>
                                                <Image src={FileService.preview(image.uid)} preview={{mask: false}} draggable={false}
                                                       className={"attachment-full size-full cursor-pointer clicked_alessandria_1 " + (i === 0 ? 'flex-active' : '')}
                                                />
                                            </li>
                                        ))}
                                    </ol>
                                </Image.PreviewGroup>
                                <div className={'mt-4 color-brown'}>
                                    {house.files.map(file => <div>
                                        <a href={FileService.preview(file.uid)} target={'_blank'}>
                                            <FilePdfOutlined/> {file.name}
                                        </a>
                                    </div>)}
                                </div>
                            </div>

                            <div className="summary entry-summary">
                                {user?.roles.includes(ROLES.EDITOR) && (
                                    <div className={'position-absolute'} style={{top: 5, right: 5, zIndex: 1}}>
                                        <Button shape={'circle'}
                                                onClick={() => setEditHouse(house)}
                                                icon={<EditOutlined/>} className={'mb-1'}>
                                        </Button>
                                    </div>
                                )}
                                <h2 className="product_title entry-title">{house.name}</h2>


                                {/*<p style={{fontSize: "55px"}} className="price color-brown"><span*/}
                                {/*    className="woocommerce-Price-amount amount">{Number(house.priceBase || 0).toLocaleString()}</span><span*/}
                                {/*    className="woocommerce-Price-currencySymbol">Kč</span></p>*/}
                                <Row className={'mt-4'}>
                                    <Col>
                                        <Link style={{marginLeft: "8px", marginBottom: "10px"}} to="/kontakt"
                                              className={"prague-services-link a-btn-2 creative" + (animateButton ? " anima" : '')} target="_self">
                                            <span className="a-btn-line"></span>
                                            MÁM ZÁJEM </Link>
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>

                                <div className="woocommerce-product-details__short-description">
                                    {house.priceBase && <p>Cena Hrubá stavba: <strong>{Number(house.priceBase || 0).toLocaleString()} Kč</strong> bez DPH</p>}
                                    {house.priceAll && <p>Cena na kľúč (holodom): <strong>{Number(house.priceAll|| 0).toLocaleString()} Kč</strong> vrátane DPH</p>}
                                    {house.area && <p>Úžitková plocha: <strong>{house.area} m2</strong></p>}
                                    {house.note && <div><br/>
                                        <strong>
                                            <p className={'font-bold'} dangerouslySetInnerHTML={{__html: house.note || ''}}></p>
                                        </strong>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> : <></>
}

const mapStateToProps = ({setup}: IStore) => {
    const {sipHouses, user} = setup
    return {sipHouses, user}
}

const mapDispatchToProps = {
    update: (changes: ISetupStateUpdate) => update(changes),
}


export default connect(mapStateToProps, mapDispatchToProps)(SipDetail);