import {Link} from "react-router-dom";
import {IRealization} from "../../../api/entity/home/IRealization";
import {IRecordsOrder} from "../../../api/entity/home/IRecordsOrder";
import {ISetupState} from "../../../redux/reducers/Setup";
import React, {FC, useEffect, useState} from "react";
import RecordsOrderService, {REALIZATION_ORDER} from "../../../api/service/home/RecordsOrderService";
import {Alert, Button, Col, Image, Popconfirm, Row} from "antd";
import {DeleteOutlined, DragOutlined, EditOutlined, EyeInvisibleOutlined, PlusOutlined} from "@ant-design/icons";
import {IStore} from "../../../redux/IStore";
import {update} from "../../../redux/actions/Setup";
import {connect} from "react-redux";
import SortableContainer from "../../../shared/structure/sortable/SortableContainer";
import SortableItem from "../../../shared/structure/sortable/SortableItem";
import RealizationService from "../../../api/service/home/RealizationService";
import RealizationForm from "./form/RealizationForm";
import {IUser, ROLES} from "../../../api/entity/security/IUser";
import FileService from "../../../api/service/FileService";
import EditableText from "./component/EditableText";

interface IProps {
    realizations: IRealization[],
    recordsOrder: IRecordsOrder[]
    update: (changes: { [key in keyof ISetupState]?: ISetupState[key] }) => void
    user?: IUser
}

const Realization: FC<IProps> = ({realizations, update, recordsOrder, user}) => {

    const [editHouse, setEditHouse] = useState<IRealization>();

    useEffect(() => {
        let result: IRealization[] = []

        const main = recordsOrder.find(m => m.id === REALIZATION_ORDER)
        if (main) {
            realizations.forEach(function (a) {
                if (main.order.indexOf(a.id) > -1){
                    result[main.order.indexOf(a.id)] = a
                }
            });
            realizations.forEach(function (a) {
                if (main.order.indexOf(a.id) === -1) {
                    result.push(a)
                }
            });
            update({realizations: result})
        }

    }, [recordsOrder]);

    const editNew = () => {
        setEditHouse({id: '', name: '', location: '', images: []})
    }

    const onFinish = (house?: IRealization) => {
        setEditHouse(undefined)
        if (house) {
            const houses = [...realizations]
            const index = houses.findIndex(h => h.id === house?.id)
            if (index > -1) {
                houses[index] = house
            } else {
                houses.push(house)
            }
            update({realizations: houses})
        }
    }

    const onDragEnd = (ids: string[]) => {
        let result: IRealization[] = []

        realizations.forEach(function (a) {
            result[ids.indexOf(a.id)] = a
        });
        RecordsOrderService.create({id: REALIZATION_ORDER, order: result.map(h => h.id)}).then(order => {
            let temp = [...recordsOrder]
            const index = temp.findIndex(o => o.id === order.id)
            temp[index] = order
            update({recordsOrder: temp})
        })

        update({realizations: result})
    }

    function remove(house: IRealization) {
        RealizationService.delete(house.id).then(() => {
            update({realizations: realizations.filter(h => h.id !== house.id)})
        })
    }

    function renderHouse(ref: (node: (HTMLElement | null)) => void, styles: React.CSSProperties, isDragging: boolean, realization: IRealization, props: any) {
        return <div ref={ref}
                    key={realization.images[0]?.name}
                    style={{...styles}}
                    className={'gallery-grid-item ' + (isDragging ? 'is-dragging p-0' : '')}>
            <div className={"portfolio-item-wrapp"}>
                <div className="portfolio-item">
                    <div className="project-masonry-wrapper">
                        <Link className="project-masonry-item-img-link"
                              to={"/realizace/" + encodeURI(realization.name.replaceAll(' ', '_'))}>
                            <div className={'project-masonry-item-img'} >
                                {realization.isPublic === false && <div className="overlay z-index-1">
                                    <Alert icon={<EyeInvisibleOutlined style={{color: 'black'}}/>} style={{backgroundColor: '#c7c7c7'}} message="Neveřejný" banner />
                                </div>}
                                <Image key={realization.images[0]?.name}
                                       src={FileService.preview(realization.images[0]?.uid)}
                                       alt={realization.name}
                                       className={'scale'}
                                       wrapperClassName={'w-100'}
                                       preview={false}
                                />
                            </div>


                            <div className="project-masonry-item-content">
                                <h4 className="project-masonry-item-title">{realization.name}</h4>
                                <div className="project-masonry-item-category">
                                    {realization.location}
                                </div>
                            </div>
                        </Link>
                        {user?.roles.includes(ROLES.EDITOR) && (
                            <div className={'position-absolute'} style={{top: 5, right: 5, zIndex: 1}}>
                                <Col className={'flex flex-column'}>
                                    <Button shape={'circle'}
                                            onClick={() => setEditHouse(realization)}
                                            icon={<EditOutlined/>} className={'mb-1'}/>
                                    <Button shape={'circle'}  {...props} style={{cursor: 'move'}}
                                            icon={<DragOutlined/>} className={'mb-1'}/>
                                    <Popconfirm onConfirm={() => remove(realization)} title={'Opravdu smazat?'}>
                                        <Button danger={true} shape={'circle'} icon={<DeleteOutlined/>}/>
                                    </Popconfirm>
                                </Col>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    }

    const houses = realizations.filter(h => user?.roles.includes(ROLES.EDITOR) || h.isPublic !== false)

    return <div>
        {!!editHouse && (
            <RealizationForm onFinish={onFinish} house={editHouse}/>
        )}
        <div className="container-fluid no-padd">
            <div className="row-fluid margin-lg-145t margin-sm-100t">

                <Row justify={"space-around"} align={'middle'}>
                    <div></div>
                    <div>
                        <h1 style={{marginBottom: "10px"}}
                            className="title d-flex justify-content-center">Realizace</h1>
                        <div style={{marginBottom: "20px"}} className=" d-flex justify-content-center">
                            <EditableText wrap={true} id={'realizations.intro.description'} inputType={'wysiwyg'} fallback={
                                <p> Kliknutím na obrázek se vám zobrazí více info</p>
                            }/>
                        </div>
                    </div>
                    <div>
                        {user?.roles.includes(ROLES.EDITOR) && (
                            <Button shape={'circle'} onClick={editNew} icon={<PlusOutlined/>}/>
                        )}
                    </div>
                </Row>
                <div className="col-sm-12 mb-4">
                    <div className="vc_column-inner">
                        <div data-unique-key="14ad5b356d917aa464e6341724c17a46" className="js-load-more"
                             data-start-page="1" data-max-page="1" data-next-link="">
                            <div className={'gallery-grid'}>
                                <SortableContainer onSort={onDragEnd} ids={houses.map(h => h.id) || []}
                                                   mode={'grid'}
                                                   renderOverlay={id => {
                                                       const draggedHouse = houses.find(h => h.id === id)
                                                       return draggedHouse ? <SortableItem key={draggedHouse.id}
                                                                                           render={(styles, ref, isDragging, props) => renderHouse(ref, styles, isDragging, draggedHouse, props)}
                                                                                           id={draggedHouse.id}/> : <></>
                                                   }}>
                                    {houses.map((house) => (
                                        <SortableItem key={house.id}
                                                      render={(styles, ref, isDragging, props) => renderHouse(ref, styles, isDragging, house, props)}
                                                      id={house.id}/>
                                    ))}
                                </SortableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


const mapStateToProps = ({setup}: IStore) => {
    const {realizations, recordsOrder, user} = setup
    return {realizations, recordsOrder: recordsOrder, user}
};

const mapDispatchToProps = {
    update: (changes: { [key in keyof ISetupState]?: ISetupState[key] }) => update(changes),
}


export default connect(mapStateToProps, mapDispatchToProps)(Realization);