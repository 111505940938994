import React, {FC, useEffect, useState} from "react";
import {IStore} from "../../../redux/IStore";
import {connect} from "react-redux";
import {update} from "../../../redux/actions/Setup";
import {ISetupState, ISetupStateUpdate} from "../../../redux/reducers/Setup";
import RecordsOrderService, {SIP_HOUSE_ORDER} from "../../../api/service/home/RecordsOrderService";
import {IRecordsOrder} from "../../../api/entity/home/IRecordsOrder";
import {IUser, ROLES} from "../../../api/entity/security/IUser";
import {Alert, Button, Col, Popconfirm, Row} from "antd";
import {Link} from "react-router-dom";
import {
    DeleteOutlined,
    DragOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    FilePdfOutlined,
    PlusOutlined
} from "@ant-design/icons";
import FileService from "../../../api/service/FileService";
import EditableText from "./component/EditableText";
import SortableContainer from "../../../shared/structure/sortable/SortableContainer";
import SortableItem from "../../../shared/structure/sortable/SortableItem";
import {ISipHouse} from "../../../api/entity/home/ISipHouse";
import SipHouseService from "../../../api/service/home/SipHouseService";
import SipForm from "./form/SipForm";


interface IProps {
    sipHouses: ISipHouse[],
    recordsOrder: IRecordsOrder[]
    update: (changes: ISetupStateUpdate) => void,
    user?: IUser
}

const SipCatalog: FC<IProps> = ({sipHouses, update, recordsOrder, user}) => {

    const [editHouse, setEditHouse] = useState<ISipHouse>();

    useEffect(() => {
        let result: ISipHouse[] = []

        const main = recordsOrder.find(m => m.id === SIP_HOUSE_ORDER)
        if (main) {
            sipHouses.forEach(function (a) {
                if (a && main.order.indexOf(a.id) > -1) {
                    result[main.order.indexOf(a.id)] = a
                }
            });
            sipHouses.forEach(function (a) {
                if (a && main.order.indexOf(a.id) === -1) {
                    result.push(a)
                }
            });
            update({sipHouses: result.filter(a => !!a)})
        }

    }, [recordsOrder, editHouse]);

    const editNew = () => {
        setEditHouse({id: '', name: '', images: [], files: []})
    }

    const onFinish = (house?: ISipHouse) => {
        setEditHouse(undefined)
        if (house) {
            const houses = [...sipHouses]
            const index = houses.findIndex(h => h.id === house?.id)
            if (index > -1) {
                houses[index] = house
            } else {
                houses.push(house)
            }
            update({sipHouses: houses})
        }
    }

    const onDragEnd = (ids: string[]) => {
        let result: ISipHouse[] = []

        sipHouses.forEach(function (a) {
            result[ids.indexOf(a.id)] = a
        });
        RecordsOrderService.create({id: SIP_HOUSE_ORDER, order: result.map(h => h.id)}).then(order => {
            let temp = [...recordsOrder]
            const index = temp.findIndex(o => o.id === order.id)
            temp[index] = order
            update({recordsOrder: temp})
        })

        update({sipHouses: result})
    }

    function remove(house: ISipHouse) {
        SipHouseService.delete(house.id).then(() => {
            update({sipHouses: sipHouses.filter(h => h.id !== house.id)})
        })
    }

    function renderHouse(ref: (node: (HTMLElement | null)) => void, styles: React.CSSProperties, isDragging: boolean, house: ISipHouse, props: any) {
        return <Col xs={24} sm={12} lg={8} ref={ref}
                    style={{...styles, maxWidth: '380px'}}
                    key={house.images[0]?.name}
                    className={isDragging ? 'is-dragging p-0' : ''}>
            <div className="project-grid-wrapper zoom-in">
                <Link className="project-grid-item-img-link"
                      to={"/sip/katalog/" + encodeURI(house.name.replaceAll(' ', '_'))}>
                    <div className="project-grid-item-img">
                        {house.isPublic === false && <div className="overlay z-index-1">
                            <Alert icon={<EyeInvisibleOutlined style={{color: 'black'}}/>} style={{backgroundColor: '#c7c7c7'}} message="Neveřejný" banner />
                        </div>}
                        <img src={FileService.preview(house.images[0]?.uid || '')} className="" alt={house.name}/>
                    </div>
                </Link>

                <div className="project-grid-item-content">
                    <Row justify={"center"} align={'middle'}>
                        <Col>
                            <h4 className="project-grid-item-title">
                                <Link className={'text-uppercase'}
                                      to={"/sip/katalog/" + encodeURI(house.name.replaceAll(' ', '_'))}>{house.name}</Link>
                            </h4>
                            <div
                                className="project-grid-item-category">{house.area} m2
                            </div>
                        </Col>
                    </Row>
                    {user?.roles.includes(ROLES.EDITOR) && (
                        <div className={'position-absolute'} style={{top: 5, right: 5, zIndex: 1}}>
                            <Col className={'flex flex-column p-0'}>
                                <Button shape={'circle'}
                                        onClick={() => setEditHouse(house)}
                                        icon={<EditOutlined/>} className={'mb-1'}/>
                                <Button shape={'circle'}  {...props} style={{cursor: 'move'}}
                                        icon={<DragOutlined/>} className={'mb-1'}/>
                                <Popconfirm onConfirm={() => remove(house)} title={'Opravdu smazat?'}>
                                    <Button danger={true} shape={'circle'} icon={<DeleteOutlined/>}/>
                                </Popconfirm>
                            </Col>
                        </div>
                    )}
                </div>
            </div>
        </Col>
    }

    const houses = sipHouses.filter(h => user?.roles.includes(ROLES.EDITOR) || h.isPublic !== false)
    return <div className="container no-padd margin-lg-145t margin-lg-145b margin-sm-100t margin-sm-70b">
        {!!editHouse && (
            <SipForm onFinish={onFinish} house={editHouse}/>
        )}
        <Row justify={"space-around"} align={'middle'}>
            <div></div>
            <h1 className="d-flex justify-content-center mb-2 title">Katalog projektů</h1>
            <div>
                {user?.roles.includes(ROLES.EDITOR) && (
                    <Button shape={'circle'} onClick={editNew} icon={<PlusOutlined/>}/>
                )}
            </div>
        </Row>
        <p className={'mb-4 text-center'}>
            <b>Dokumenty na zobrazenie:</b>
            <div className={'color-brown'}>
                <a href={'/docs/vyplne.pdf'} target={'_blank'}>
                    <FilePdfOutlined/> Výplne
                </a>
            </div>
            <div className={'color-brown'}>
                <a href={'/docs/SIP_certifikat.pdf'} target={'_blank'}>
                    <FilePdfOutlined/> Certifikát pre SIP panely
                </a>
            </div>
        </p>
        <div className="row-fluid">
            <div className="col-sm-12 no-padd">
                <div data-unique-key="d30797fabd788147235e48099dfaacc0" className="js-load-more" data-start-page="1"
                     data-max-page="3">
                    <Row gutter={[32, 20]} justify={"space-around"} className={'w-100 m-0'}>
                    <SortableContainer onSort={onDragEnd} ids={houses.map(h => h.id) || []} mode={'grid'}
                                           renderOverlay={id => {
                                               const draggedHouse = houses.find(h => h.id === id)
                                               return draggedHouse ? <SortableItem key={draggedHouse.id}
                                                                                   render={(styles, ref, isDragging, props) => renderHouse(ref, styles, isDragging, draggedHouse, props)}
                                                                                   id={draggedHouse.id}/> : <></>
                                           }}>
                            {houses.map(house => (
                                <SortableItem key={house.id}
                                              render={(styles, ref, isDragging, props) => renderHouse(ref, styles, isDragging, house, props)}
                                              id={house.id}/>
                            ))}
                        </SortableContainer>
                    </Row>
                </div>
            </div>
        </div>
    </div>
}


const mapStateToProps = ({setup}: IStore) => {
    const {sipHouses, recordsOrder, user} = setup
    return {sipHouses, recordsOrder: recordsOrder, user}
};

const mapDispatchToProps = {
    update: (changes: { [key in keyof ISetupState]?: ISetupState[key] }) => update(changes),
}


export default connect(mapStateToProps, mapDispatchToProps)(SipCatalog);