import ApiService from "../ApiService";
import {IContactForm} from "../../entity/home/IContactForm";

const COLLECTION = 'contact-form'

interface IResponse {
    success: boolean
    message: string
}

const ContactFromService = {
    send: (data: IContactForm): Promise<IResponse> => {
        return ApiService.create(COLLECTION, data)
    }
}

export default ContactFromService