import {Row} from "antd";
import React, {FC} from "react";

interface IProps {

}

const NotFound: FC<IProps> = ({}) => {

    return <Row style={{marginTop: 100, padding: 200}} justify={'center'} align={'middle'}>
        <h1>
            nothing here :/
        </h1>
    </Row>
}


export default NotFound