import React, {useState} from 'react';
import {DragOutlined, PlusOutlined} from '@ant-design/icons';
import type {UploadFile, UploadProps} from 'antd';
import {Button, GetProp, Image, Upload} from 'antd';
import {RcFile} from 'antd/es/upload';
import SortableContainer from "../structure/sortable/SortableContainer";
import SortableItem from "../structure/sortable/SortableItem";
import {UploadListType} from "antd/es/upload/interface";
import FileService from "../../api/service/FileService";

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

interface IProps {
    onChange?: (files: UploadFile | UploadFile[]) => void
    value?: UploadFile | UploadFile[],
    accept?: string[],
    multiple?: boolean
    listType?: UploadListType
}

const FileUpload: React.FC<IProps> = ({onChange, value, accept, multiple}) => {

    const [fileList, setFileList] = useState<UploadFile[]>((Array.isArray(value) ? value : (value ? [value] : [])).map(v => ({...v, url: FileService.preview(v.uid)})));
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');


    const getBase64 = (file: FileType): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const dataURLToBlob = function (dataURL: string) {
        let raw;
        let contentType;
        let parts;
        const BASE64_MARKER = ';base64,';
        if (dataURL.indexOf(BASE64_MARKER) === -1) {
            parts = dataURL.split(',');
            contentType = parts[0].split(':')[1];
            raw = parts[1];

            return new Blob([raw], {type: contentType});
        }

        parts = dataURL.split(BASE64_MARKER);
        contentType = parts[0].split(':')[1];
        raw = window.atob(parts[1]);
        const rawLength = raw.length;

        const uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], {type: contentType});
    };

    const resize = (file: RcFile) => {
        return new Promise<RcFile>((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = document.createElement('img');
                img.src = reader.result as string;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;
                    const MAX_WIDTH = 1920;
                    const MAX_HEIGHT = 1920;
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext("2d")?.drawImage(img, 0, 0, width, height);
                    const dataUrl = canvas.toDataURL('image/jpeg');
                    const resizedImage = dataURLToBlob(dataUrl);
                    resolve(resizedImage as RcFile)
                };
            };
        });
    }


    const onDragEnd = (ids: string[]) => {
        let result: UploadFile[] = []

        fileList.forEach(function (a) {
            result[ids.indexOf(a.uid)] = a
        });
        setFileList(result)
        onChange?.(multiple ? result : result[0])
    }

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const handleChange: UploadProps['onChange'] = async ({file, fileList: newFileList}) => {
        if (!multiple){
            const newFile = newFileList.find(f => file.uid === f.uid)
            newFileList = newFile ? [newFile] : []
        }

        setFileList(newFileList);
        const files: UploadFile[] = await Promise.all(newFileList.map(async (item): Promise<UploadFile> => {
            if (item.originFileObj && item.type?.split('/')[0] === 'image') {
                item.originFileObj = await resize(item.originFileObj)
                return item
            }
            return item;
        }));
        setFileList(files);
        onChange?.(multiple ? files : files[0])
    }

    const uploadButton = (
        <Button style={{border: 0, background: 'none'}}>
            <PlusOutlined/>
            <div style={{marginTop: 8}}>Nahrát</div>
        </Button>
    )


    console.log(fileList)

    return (
        <div>
            <SortableContainer onSort={onDragEnd} ids={fileList.map(f => f.uid)} mode={'grid'}>
                <Upload
                    fileList={fileList}
                    listType={'picture-card'}
                    multiple={multiple}
                    accept={accept?.join(', ')}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    showUploadList={{showPreviewIcon: fileList[0]?.type?.includes('image')}}
                    beforeUpload={() => false}
                    itemRender={(originNode, file) => (
                        <SortableItem key={file.uid} render={(styles, ref, isDragging, props) => (
                            <div ref={ref} style={styles}
                                 className={'position-relative h-100' + (isDragging ? 'is-dragging' : '')}>
                                {originNode}
                                <Button shape={'circle'} className={'position-absolute'} style={{top: -5, right: -5, cursor: 'move', zIndex: 1}}
                                        icon={<DragOutlined/>}  {...props}/>
                            </div>
                        )} id={file.uid}/>
                    )}
                >
                    {uploadButton}

                </Upload>
            </SortableContainer>
            {previewImage && (
                <Image
                    wrapperStyle={{display: 'none'}}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>

    );
};

export default FileUpload