import {Alert, Button, Col, Form, Input, InputNumber, Modal, Row, Tooltip} from "antd";
import {DragOutlined, EyeInvisibleOutlined, EyeOutlined, HomeOutlined, SaveOutlined} from '@ant-design/icons'
import React, {FC, useState} from "react";
import Wysiwyg from "../../../../shared/wysiwyg/Wysiwyg";
import FileUpload from "../../../../shared/input/FileUpload";
import FileService from "../../../../api/service/FileService";
import {decimalSeparator, uuid} from "../../../../shared/Utils";
import {UploadFileStatus} from "antd/es/upload/interface";
import {IFile} from "../../../../api/entity/file/IFile";
import {IStore} from "../../../../redux/IStore";
import {connect} from "react-redux";
import {ISipHouse} from "../../../../api/entity/home/ISipHouse";
import SipHouseService from "../../../../api/service/home/SipHouseService";

interface IProps {
    house: ISipHouse
    onFinish: (house?: ISipHouse) => void
    sipHouses: ISipHouse[]
}

const SipForm: FC<IProps> = ({house, onFinish}) => {

    const [form] = Form.useForm();

    const [saving, setSaving] = useState(false);
    const [onHomepage, setOnHomepage] = useState(house.onHomepage);
    const [isPublic, setPublic] = useState(house.isPublic === undefined ? true : house.isPublic);


    const onSubmit = async (values?: ISipHouse) => {
        let files: IFile[] = []
        setSaving(true)
        const newImages = values?.images.filter(i => !house.images.find(image => image.uid === i.uid)) || []
        const newFiles = values?.files.filter(i => !house.files.find(file => file.uid === i.uid)) || []
        if (newImages.length || newFiles.length) {
            const formData = new FormData()
            newImages.forEach(image => {
                if (image.originFileObj) {
                    formData.append('files[]', image.originFileObj, image.name)
                }
            })
            newFiles.forEach(file => {
                if (file.originFileObj) {
                    formData.append('files[]', file.originFileObj, file.name)
                }
            })
            await FileService.upload?.(formData).then(results => files = results)
        }

        const data = {
            ...house, ...values,
            isPublic,
            onHomepage : isPublic && onHomepage,
            images: values?.images.map(image => {
                const uploaded = files.find(f => f.label === image.name)
                return {
                    ...(uploaded ? {
                        name: uploaded.label,
                        uid: uploaded.id,
                        status: 'done' as UploadFileStatus,
                        type: uploaded.type
                    } : image)
                }
            }) || [],
            files: values?.files.map(file => {
                const uploaded = files.find(f => f.label === file.name)
                return {
                    ...(uploaded ? {
                        name: uploaded.label,
                        uid: uploaded.id,
                        status: 'done' as UploadFileStatus,
                        type: uploaded.type
                    } : file)
                }
            }) || []
        }

        SipHouseService.create({
            ...data,
            id: house.id || uuid()
        }).then((result: ISipHouse) => {
            onFinish(result)
        }).finally(() => setSaving(false))
    }

    return <Modal title={'Upravit'} width={800} open={true} onCancel={() => onFinish()}
                  styles={{body: {maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden'}}}
                  classNames={{footer: 'border-top'}}
                  footer={[
                      <Row key={'row'} justify={"space-between"} className={'mt-2'}>
                          <Col>
                              <Tooltip title={onHomepage ? 'Odebrat z domovské stránky' : 'Zobrazit na domovské stránce'}>
                                  <Button icon={<HomeOutlined/>} type={onHomepage ? 'primary' : undefined} disabled={!isPublic}
                                          onClick={() => setOnHomepage(!onHomepage)} className={'mr-2 border-top'}></Button>
                              </Tooltip>
                              <Tooltip title={isPublic ? 'Skrýt tento dům' : 'Zveřejnit tento dům'}>
                                  <Button icon={isPublic ? <EyeOutlined/> :
                                      <EyeInvisibleOutlined/>} type={isPublic ? 'primary' : undefined}
                                          onClick={() => setPublic(!isPublic)} className={'mr-2 border-top'}/>
                              </Tooltip>
                          </Col>
                          <Col>
                              <Button onClick={() => onFinish()} className={'mr-2'}>Zrušiť</Button>
                              <Button onClick={() => form.validateFields().then(onSubmit)} type={"primary"}
                                      icon={<SaveOutlined/>} loading={saving}>Uložiť</Button>
                          </Col>
                      </Row>
                  ]}>
        <Form layout={"vertical"} form={form} onFinish={onFinish} initialValues={{...house}} className={'w-100'}>
            <Row className={'mt-2 w-100 p-1'}>
                <Col>
                    <Form.Item label={'Název'} name={'name'} className={'mb-1'}
                               rules={[{required: true, message: 'Políčko je vyžadováno'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Cena - hrubá stavba:'} name={'priceBase'}>
                        <InputNumber className={'w-100'} addonAfter={'Kč'} formatter={value => value ? Number(value).toLocaleString() : ''} parser={value => {
                            const replace = `[^\\d\\${decimalSeparator()}]`;
                            const regExp = new RegExp(replace, "g");
                            return value ?
                                value.replace(regExp, '').replaceAll(',', '.') : ''
                        }}/>
                    </Form.Item>
                    <Form.Item label={'Cena - na kľúč (holodom)'} name={'priceAll'}>
                        <InputNumber className={'w-100'} addonAfter={'Kč'} formatter={value => value ? Number(value).toLocaleString() : ''} parser={value => {
                            const replace = `[^\\d\\${decimalSeparator()}]`;
                            const regExp = new RegExp(replace, "g");
                            return value ?
                                value.replace(regExp, '').replaceAll(',', '.') : ''
                        }}/>
                    </Form.Item>
                    <Form.Item label={'Výměra'} name={'area'}>
                        <InputNumber className={'w-100'} addonAfter={'m2'}/>
                    </Form.Item>
                    <Form.Item label={'Popis'} name={'note'}>
                        <Wysiwyg/>
                    </Form.Item>
                    <Form.Item label={'Soubory'} name={'files'}>
                        <FileUpload multiple={true} accept={['.pdf,.doc,.docx']}/>
                    </Form.Item>
                    <Form.Item label={'Obrázky'} name={'images'}>
                        <FileUpload multiple={true} accept={['image/*']}/>
                    </Form.Item>
                    <Alert className={'py-2'} description={<div className={'f-small'}>
                        První obrázek je vždy profilový obrázek <br/>
                        Řadit můžete přetažením pomocí tlačítka s ikonou <DragOutlined/>
                    </div>}/>
                </Col>
            </Row>
        </Form>
    </Modal>
}

const mapStateToProps = ({setup}: IStore) => {
    const {sipHouses} = setup
    return {sipHouses}
}


export default connect(mapStateToProps)(SipForm);