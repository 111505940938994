import React, {FC} from "react";
import {IStore} from "../../../redux/IStore";
import {connect} from "react-redux";
import {update} from "../../../redux/actions/Setup";
import {ISetupState} from "../../../redux/reducers/Setup";
import EditableText from "./component/EditableText";
import {Col, Row} from "antd";


interface IProps {

}

const SipPanel: FC<IProps> = ({}) => {

    return <div>
        <div className="container padd-only-xs margin-xs-20t margin-md-0t">
            <div className="row">
                <div className="col-xs-12 padd-only-xs">
                    <div className="container no-padd">
                        <Row className="row-fluid margin-lg-190t" gutter={30}>
                            <Col sm={12} xs={24}>
                                <div className="padd-only">
                                    <div className="">
                                        <h2 className="title mt-4">
                                            SIP Panely
                                        </h2>
                                        <div className="content padding-xs-40b position-relative">
                                            <EditableText id={'sip.description'} inputType={'wysiwyg'} fallback={<div>
                                                <p>Specializujeme se na stavební systém SIP (Structured Insulated
                                                    Panel), který se celosvětově používá již 40 let (v Kanadě 80% a v
                                                    USA 65% všech rodinných domů je postaveno touto jedinečnou
                                                    technologií).</p>
                                                <p>
                                                    Domy ze SIP panelů jsou energeticky úsporné a vyžadují minimální
                                                    náklady na jejich provoz a údržbu..
                                                </p>
                                            </div>}>
                                            </EditableText>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} xs={24}>
                                <div className="no-padd-inner">
                                    <div className="ml-4">
                                        <div className={'sip-image position-relative'}>
                                            <img src="/img/sip/about.png"
                                                 className="prague-team-img border-radius-10 o-cover "
                                                 alt={'about'}/>
                                            <div className={'tag'}>
                                                Složení SIP panelů
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
        <div className="container padd-only-xs margin-xs-20t margin-md-0t">
            <div className="row">
                <div className="col-xs-12 padd-only-xs">
                    <div className="container no-padd">
                        <Row className="row-fluid margin-lg-90t" gutter={30}>
                            <Col sm={12} xs={24}>
                                <div className="padd-only">
                                    <div className="left dark">
                                        <h2 className="title">
                                            Co jsou SIP panely?
                                        </h2>
                                        <div className="content padding-xs-40b position-relative">
                                            <EditableText id={'sip.panel.explanation'} inputType={'wysiwyg'}
                                                          fallback={<div>
                                                              <p>Jádro panelu musí být dostatečně pevné, proto panel
                                                                  nepotřebuje žádné
                                                                  další ztužující prvky. Proto se panel SIP také
                                                                  označuje jako
                                                                  sendvičový izolovaný panel bez vyztužených žeber.
                                                                  Celoplošným
                                                                  slepením OSB desek (obalu) s izolačním polystyrenovým
                                                                  jádrem vznikne
                                                                  velmi tuhý prvek, který se chová jako krabicový
                                                                  nosník. Domy
                                                                  postavené z těchto panelů jsou velmi odolné a pevné. V
                                                                  roce 2004
                                                                  bylo v USA na základě provedených testů povoleno
                                                                  používat stavební
                                                                  technologii SIP ve všech seismicky ohrožených
                                                                  oblastech. Podle údajů
                                                                  asociace sdružující producenty SIP v USA byla tato
                                                                  technologie
                                                                  vládou doporučena pro obnovu oblastí postižených
                                                                  hurikány. Panely
                                                                  mohou být vyráběny z různých materiálů.</p>
                                                          </div>}>
                                            </EditableText>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} xs={24}>
                                <div className="no-padd-inner">
                                    <div className="ml-4">
                                        <div className={'sip-image position-relative'}>
                                            <img src="/img/sip/explanation.png"
                                                 className="prague-team-img border-radius-10 o-cover "
                                                 alt={'about'}/>
                                            <div className={'tag'}>
                                                Sip panely
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
        <div className="container padd-only-xs margin-lg-35t">
            <div className="row">
                <div className="col-xs-12 padd-only-xs">
                    <div className="container no-padd">
                        <Row className="row-fluid" gutter={30}>
                            <Col sm={12} xs={24}>
                                <div className="padd-only">
                                    <div className="left dark">
                                        <h3 className="title disabled">
                                            PLÁŠŤ
                                        </h3>
                                        <div className="content padding-xs-40b position-relative">
                                            <EditableText id={'sip.panel.cover.description'} inputType={'wysiwyg'}
                                                          fallback={<div>
                                                              <p>Nejčastěji se dnes používají desky z orientovaných
                                                                  velkoplošných
                                                                  třísek OSB. Tyto desky mají vynikající mechanické
                                                                  vlastnosti při
                                                                  nízké hmotnosti, velmi jednoduše se zpracovávají, jak
                                                                  při výrobě
                                                                  panelů, tak na stavbě, a jsou cenově dostupné.</p>
                                                          </div>}>
                                            </EditableText>
                                        </div>
                                        <h3 className="title margin-lg-35t">
                                            JÁDRO
                                        </h3>
                                        <div className="content padding-xs-40b position-relative">
                                            <EditableText id={'sip.panel.core.description'} inputType={'wysiwyg'}
                                                          fallback={<div>
                                                              <p>Vyrábí se z EPS, tedy z polystyrenu. Je možné použít i
                                                                  XPS,
                                                                  extrudovaný polystyren, nebo polyuretan. Jelikož se
                                                                  jedná o plošné
                                                                  stavební dílce, které jsou vyráběny průmyslově s
                                                                  velkou přesností,
                                                                  jsou i vlastní stavby velmi precizní. Z panelů se
                                                                  zhotovují obvodové
                                                                  stěny a vnitřní nosné příčky. Univerzální použitelnost
                                                                  panelů
                                                                  zjednodušuje navrhování staveb a logistiku výstavby.
                                                                  SIP je
                                                                  technologie, tzv. „suché výstavby“, nevyžaduje žádné
                                                                  speciální
                                                                  vybavení staveniště a montážních partií. SIP panely
                                                                  lze vyrábět jako
                                                                  velkoplošné prvky s připravenými otvory pro okna a
                                                                  dveře..</p>
                                                          </div>}>
                                            </EditableText>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} xs={24}>
                                <div className="no-padd-inner">
                                    <div className="ml-4">
                                        <div className={'sip-image position-relative'}>
                                            <img src="/img/sip/inside.jpg"
                                                 className="prague-team-img border-radius-10 o-cover "
                                                 alt={'about'}/>
                                            <div className={'tag'}>
                                                Vnútro SIP
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
        <div className="container padd-only-xs margin-lg-90t">
            <div className="row">
                <div className="col-xs-12 padd-only-xs">
                    <div className="container no-padd">
                        <h2 className="title disabled mb-4">
                            Výhody SIP panelů
                        </h2>
                        <Row className="row-fluid pt-4" gutter={30}>
                            <Col sm={12} xs={24}>
                                <div className="padd-only">
                                    <div className="left dark">
                                        <h3 className="title disabled mt-4">
                                            ODOLNOST VŮČI VLHKOSTI
                                        </h3>
                                        <div className="content padding-xs-40b position-relative">
                                            <EditableText id={'sip.panel.humidity.description'} inputType={'wysiwyg'}
                                                          fallback={<div>
                                                              <p>Odolnost sendvičových panelů SIP proti vlhkosti závisí
                                                                  na značce OSB desky a samozřejmě na svědomitosti
                                                                  výrobce těchto desek. K výrobě sendvičových panelů
                                                                  používáme OSB desky EGGER typu 3 (odolné vůči
                                                                  vlhkosti). Výrobce talířů EGGER je certifikován na
                                                                  Ukrajině a pečlivě sleduje kvalitu jejich výroby. Naše
                                                                  společnost také kontroluje kvalitu materiálů, což nám
                                                                  umožňuje nabídnout vývojářům maximální záruku důvěry
                                                                  na naše produkty.</p>
                                                          </div>}>
                                            </EditableText>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} xs={24}>
                                <div className="content padding-xs-40b position-relative">
                                    <h3 className="title disabled mt-4">
                                        ODOLNOST VŮČI POŽÁRU
                                    </h3>
                                    <EditableText id={'sip.panel.fire.description'} inputType={'wysiwyg'}
                                                  fallback={<div>
                                                      <p>Desky OSB-3 mají složky zpomalující hoření, které výrazně
                                                          zabraňují vznícení, spolu se sádrokartonem (tloušťka &gt; 9
                                                          mm)
                                                          poskytuje spolehlivou ochranu intrapanelové polystyrenové pěny
                                                          po dobu 45 minut nepřetržitého kontaktu s otevřeným ohněm.
                                                          Vyrábíme sendvičové panely se zvýšenou požární odolností
                                                          (třída SE dle mezinárodní klasifikace). K tomu používáme
                                                          expandovaný polystyren s přídavkem retardérů hoření vyšších
                                                          stupňů, který výrazně zpomaluje vznícení a hoření.</p>
                                                  </div>}>
                                    </EditableText>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="content padding-xs-40b position-relative">
                                    <h3 className="title disabled mt-4">
                                        OCHRANA PŘED HLODAVCEMI
                                    </h3>
                                    <EditableText id={'sip.panel.protection.description'} inputType={'wysiwyg'}
                                                  fallback={<div>
                                                      <p>Praxe ukázala, že v domech se sendvičovými panely se myši
                                                          nezdržují z několika důvodů: materiály sendvičových panelů,
                                                          jmenovitě OSB deska a pěna, nejsou potravou pro hlodavce a
                                                          nepoužívají je k tvoření hnízd. Vnější pevné OSB desky chrání
                                                          polyfoam před otevřeným kontaktem s hlodavci a vysoký stupeň
                                                          tepelné izolace sendvičových panelů nepřitahuje pozornost
                                                          hlodavců teplem. Kvalitní instalace panelů SIP poskytuje
                                                          úplnou izolaci a absenci trhlin v domě, což vytváří vynikající
                                                          ochranu proti hlodavcům a nepřitahuje je teplem ani
                                                          zápachem.</p>
                                                  </div>}>
                                    </EditableText>
                                </div>
                            </Col>
                            <Col sm={12} xs={24}>
                                <div className="content padding-xs-40b position-relative">
                                    <h3 className="title disabled mt-4">
                                        SÍLA
                                    </h3>
                                    <EditableText id={'sip.panel.strength.description'} inputType={'wysiwyg'}
                                                  fallback={<div>
                                                      <p>Vysoká pevnost sendvičových panelů SIP je schopnost odolávat
                                                          hmotnosti a seismickému zatížení. Použití hustší polystyrenové
                                                          pěny (polyfoam) a lepidla s vysokou přilnavostí poskytlo ještě
                                                          větší zvýšení pevnosti SIP panelů. Použití pouze
                                                          certifikovaných materiálů umožnilo naší společnosti výrazně
                                                          stabilizovat a zlepšit výkon sendvičových panelů SIP.
                                                          Sendvičový panel SIP neztrácí svoji odolnost na 80 let a více,
                                                          pokud dodržíte provozní podmínky. Dodržování technologie a
                                                          víceúrovňová kontrola kvality ve všech výrobních fázích nám
                                                          umožnily zaručit vysokou úroveň kvality produktů vyráběných
                                                          společností SIP.</p>
                                                  </div>}>
                                    </EditableText>
                                </div>
                            </Col>
                            <Col sm={12} xs={24}>
                                <div className="content padding-xs-40b position-relative">
                                    <h3 className="title disabled mt-4">
                                        TRVANLIVOST
                                    </h3>
                                    <EditableText id={'sip.panel.longevity.description'} inputType={'wysiwyg'}
                                                  fallback={<div>
                                                      <p>Trvanlivost sendvičových panelů SIP do značné míry závisí na
                                                          kvalitě izolační vrstvy (expandovaný polystyren), OSB deskách
                                                          a montáži na kanadskou technologii. Expandovaný polystyren je
                                                          spolehlivý a léty ověřený materiál. Na rozdíl od polyuretanové
                                                          pěny nebo minerální vlny se pěnový polystyren v průběhu času
                                                          nepropadává a zachovává si svůj tvar po celá desetiletí a za
                                                          předpokladu, že se zachová celistvost ochranných desek OSP –
                                                          více než 100 let. Trvanlivost sendvičových panelů výrazně
                                                          závisí na hustotě pěny. Sendvičové panely s nízkou hustotou
                                                          expandovaného polystyrenu se často používají pro domácnosti a
                                                          dočasné stavby. Při nízké hustotě se zrno pěnového polystyrenu
                                                          může po 20 letech výrazně zmenšit, což povede k tvorbě dutin a
                                                          postupnému snižování tepelněizolačních vlastností sendvičových
                                                          panelů.</p>
                                                  </div>}>
                                    </EditableText>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
        <div className="container padd-only-xs margin-lg-90t margin-lg-45b">
            <div className="row">
                <div className="col-xs-12 padd-only-xs">
                    <div className="container no-padd">
                        <h2 className="title">
                            SIP bloky
                        </h2>
                        <Row className="row-fluid margin-lg-35t" gutter={30}>
                            <Col sm={12} xs={24}>
                                <div className="padd-only">
                                    <div className="left dark">
                                        <h3 className="title">
                                            VÍCEÚČELOVÝ A SNADNÝ STAVEBNÍ MATERIÁL
                                        </h3>
                                        <div className="content padding-xs-40b position-relative">
                                            <EditableText id={'sip.panel.explanation'} inputType={'wysiwyg'}
                                                          fallback={<div>
                                                              <p>Jádro panelu musí být dostatečně pevné, proto panel
                                                                  nepotřebuje žádné
                                                                  další ztužující prvky. Proto se panel SIP také
                                                                  označuje jako
                                                                  sendvičový izolovaný panel bez vyztužených žeber.
                                                                  Celoplošným
                                                                  slepením OSB desek (obalu) s izolačním polystyrenovým
                                                                  jádrem vznikne
                                                                  velmi tuhý prvek, který se chová jako krabicový
                                                                  nosník. Domy
                                                                  postavené z těchto panelů jsou velmi odolné a pevné. V
                                                                  roce 2004
                                                                  bylo v USA na základě provedených testů povoleno
                                                                  používat stavební
                                                                  technologii SIP ve všech seismicky ohrožených
                                                                  oblastech. Podle údajů
                                                                  asociace sdružující producenty SIP v USA byla tato
                                                                  technologie
                                                                  vládou doporučena pro obnovu oblastí postižených
                                                                  hurikány. Panely
                                                                  mohou být vyráběny z různých materiálů.</p>
                                                          </div>}>
                                            </EditableText>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} xs={24}>
                                <div className="no-padd-inner">
                                    <div className="ml-4">
                                        <div className={'sip-image position-relative'}>
                                            <img src="/img/sip/blocks.jpg"
                                                 className="prague-team-img border-radius-10 o-cover "
                                                 alt={'about'}/>
                                            <div className={'tag'}>
                                                SIP bloky
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} xs={24}>
                                <div className="no-padd-inner">
                                    <div className="ml-4">
                                        <div className={'sip-image position-relative'}>
                                            <img src="/img/sip/block-detail.jpg"
                                                 className="prague-team-img border-radius-10 o-cover "
                                                 alt={'about'}/>
                                            <div className={'tag'}>
                                                Zložení SIP bloků
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} xs={24}>
                                <div className="content padding-xs-40b position-relative">
                                    <EditableText id={'sip.block.explanation'} inputType={'wysiwyg'}
                                                  fallback={<div>
                                                      <p>- Cenová dostupnost: náklady na výstavbu jsou relativně
                                                          nízké.<br/>
                                                          - Minimální doba výstavby – teplý dům na 1-2 týdny!<br/>
                                                          - Domy SIP se nesmršťují, takže ihned po úklidu můžete začít
                                                          dokončovat práce.<br/>
                                                          - Nízká hmotnost bloků SIP.<br/>
                                                          - Lehký základ: šroubový základ je instalován za 1 den!<br/>
                                                          - Snadná montáž, není potřeba žádné speciální vybavení.<br/>
                                                          - Stavět můžete po celý rok.<br/>
                                                          - Minimální poškození krajiny.<br/>
                                                          - V domě se SIP je to pohodlné jak v chladu, tak v teple.<br/>
                                                          - Místnosti v domě SIP se rychle vyhřívají.<br/>
                                                          - Není potřeba žádný výkonný systém vytápění a
                                                          klimatizace.<br/>
                                                          - Spolehlivost a nenáročnost na obsluhu.<br/>
                                                          - Dům z bloků SIP je extrémně pevný.<br/>
                                                          - „Zelená technologie“ – se SIP BLOCK chráníte životní
                                                          prostředí.<br/>
                                                      </p>
                                                  </div>}>
                                    </EditableText>
                                </div>
                            </Col>
                        </Row>
                        <div className="content margin-lg-35t position-relative">
                            <EditableText id={'sip.block.thought'} inputType={'wysiwyg'}
                                          fallback={<div>
                                              <h4>SIP BLOKY PRO STAVEBNICTVÍ. CO MŮŽE TENTO STAVEBNÍ MATERIÁL
                                                  DÁT? PODAŘÍ SE JÍ NAHRADIT JINÉ STAVEBNÍ TECHNOLOGIE? ZVAŽTE
                                                  VLASTNOSTI A MOŽNOSTI TOHOTO NOVÉHO STAVEBNÍHO MATERIÁLU.
                                              </h4>
                                          </div>}>
                            </EditableText>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


const mapStateToProps = ({setup}: IStore) => {
    const {modularHouses, recordsOrder, user} = setup
    return {modularHouses, recordsOrder: recordsOrder, user}
};

const mapDispatchToProps = {
    update: (changes: { [key in keyof ISetupState]?: ISetupState[key] }) => update(changes),
}


export default connect(mapStateToProps, mapDispatchToProps)(SipPanel);