import {Route, Routes} from "react-router-dom";
import React from "react";
import ModularHouses from "./page/ModularHouses";
import MobileHouses from "./page/MobileHouses";
import Realization from "./page/Realization";
import WhyViva from "./page/WhyViva";
import Technologies from "./page/Technologies";
import Contact from "./page/Contact";
import Home from "./page/Home";
import RealizationDetail from "./page/detail/RealizationDetail";
import ModularDetail from "./page/detail/ModularDetail";
import NotFound from "./page/component/NotFound";
import News from "./page/News";
import Sip from "./page/Sip";
import SipCatalog from "./page/SipCatalog";
import SipPanel from "./page/SipPanel";
import SipDetail from "./page/detail/SipDetail";
import SipRealization from "./page/SipRealization";
import SipRealizationDetail from "./page/detail/SipRealizationDetail";

const HomeRouter = () => {

    return <Routes>
        <Route path="/novinky" element={<News/>}/>
        <Route path="/sip/panel" element={<SipPanel/>}/>
        <Route path="/sip" element={<Sip/>}/>
        <Route path="/sip/katalog/:house" element={<SipDetail/>}/>
        <Route path="/sip/katalog" element={<SipCatalog/>}/>
        <Route path="/sip/reference/:house" element={<SipRealizationDetail/>}/>
        <Route path="/sip/reference" element={<SipRealization/>}/>
        <Route path="/modulovy-domy/:house" element={<ModularDetail/>}/>
        <Route path="/modulovy-domy" element={<ModularHouses/>}/>
        <Route path="/mobilni-domy" element={<MobileHouses/>}/>
        <Route path="/realizace" element={<Realization/>}/>
        <Route path="/realizace/:house" element={<RealizationDetail/>}/>
        <Route path="/proc-viva-house" element={<WhyViva/>}/>
        <Route path="/technologie" element={<Technologies/>}/>
        <Route path="/kontakt" element={<Contact/>}/>
        <Route path="/" element={<Home/>}/>
        <Route path="/*" element={<NotFound/>}/>
        <Route path="/not-found" element={<NotFound/>}/>
    </Routes>
}

export default HomeRouter