import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import HomeRouter from "../HomeRouter";

const Layout = () => {
    return <div>
        <Header/>
        <div>
            <HomeRouter/>
        </div>
        <Footer/>
    </div>
}

export default Layout