import {FC, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {IStore} from "../../../../redux/IStore";
import {connect} from "react-redux";
import {IRealization} from "../../../../api/entity/home/IRealization";
import {Image} from "antd";
import FileService from "../../../../api/service/FileService";

interface IProps {
    realizations: IRealization[]
}

const RealizationDetail: FC<IProps> = ({realizations}) => {
    const params = useParams();
    const [realization, setRealization] = useState<IRealization>();

    const navigate = useNavigate()


    useEffect(() => {
        const name = params['house']
        const house = realizations.find(h => h.name.replaceAll(' ', '_') === name)
        if (house) {
            setRealization(house)
        } else {
            navigate('/not-found')
        }
    }, [realizations]);

    return realization ? <div>
            <div className="container-fluid no-padd">
                <div className="row-fluid no-padd">
                    <div className="col-sm-12 no-padd">
                        <div className="container-fluid top-banner no-padd big light no-marg-bottom" style={{
                            background: "url(" + FileService.preview(realization.images[0]?.uid || '') + ")",
                            backgroundPosition: 'center',
                            backgroundSize: 'cover'
                        }}>
                            <span className="overlay"></span>
                            <div className="content">
                                <div className="subtitle">{realization?.location}</div>
                                <h1 className="title">{realization?.name}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid no-padd">
                <div className="row-fluid no-padd">
                    <div className="col-sm-12 no-padd">
                        <div className="project-detail-gallery-outer">
                            <Image.PreviewGroup preview={{toolbarRender: () => <div></div>}}>
                                <div className="gallery-grid">
                                    {realization.images.map(image => (
                                        <div className="gallery-grid-item">
                                            <div className="detail-gallery-item">
                                                <div className="detail-gallery-item-img">
                                                    <Image src={FileService.preview(image.uid)} alt={image.name}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Image.PreviewGroup>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : <div>Not found</div>
}


const mapStateToProps = ({setup}: IStore) => {
    const {realizations} = setup
    return {realizations}
}

export default connect(mapStateToProps)(RealizationDetail);