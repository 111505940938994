import {Button, Col, Form, Input, Modal, Row, Tabs} from "antd";
import {EditOutlined, SaveOutlined} from '@ant-design/icons'
import React, {FC, useEffect, useState} from "react";
import {IEditableText} from "../../../../api/entity/home/IEditableText";
import {IStore} from "../../../../redux/IStore";
import {connect} from "react-redux";
import {ISetupStateUpdate} from "../../../../redux/reducers/Setup";
import {update} from "../../../../redux/actions/Setup";
import {IUser, ROLES} from "../../../../api/entity/security/IUser";
import EditableTextService from "../../../../api/service/home/EditableTextService";
import Wysiwyg from "../../../../shared/wysiwyg/Wysiwyg";
import ReactDOMServer from 'react-dom/server';


interface IProps {
    editableTexts: IEditableText[]
    update: (changes: ISetupStateUpdate) => void
    user?: IUser
    id: string
    inputType?: 'wysiwyg' | 'textarea' | 'basic' | 'email'
    editButtonPosition?: { top: number | string, right: number | string }
    fallback?: JSX.Element
    wrap?: boolean
    wrapClassName?: string
}

const EditableText: FC<IProps> = ({id, fallback, editableTexts, update, user, inputType, editButtonPosition, wrap, wrapClassName}) => {

    const [form] = Form.useForm();

    const [saving, setSaving] = useState(false);
    const [edit, setEdit] = useState(false);
    const [versions, setVersions] = useState<{ language: string, label: string }[]>([{language: 'cz', label: 'CZ'}])

    const [text, setText] = useState<IEditableText>({
        id: id,
        versions: [{language: 'cz', text: ReactDOMServer.renderToStaticMarkup(fallback || <></>) || ''}]
    })

    useEffect(() => {
        const text = editableTexts.find(m => m.id === id)
        text && setText(text)
    }, [editableTexts]);


    const onSubmit = async (values: IEditableText) => {
        setSaving(true)

        EditableTextService.create({
            ...values,
            id: id
        }).then((result: IEditableText) => {
            const texts = [...editableTexts]
            const index = texts.findIndex(h => h.id === result?.id)
            if (index > -1) {
                texts[index] = result
            } else {
                texts.push(result)
            }
            update({editableTexts: texts})

            setEdit(false)
        }).finally(() => setSaving(false))
    }

    function renderText() {
        return <>{inputType === "wysiwyg" ? <text dangerouslySetInnerHTML={{__html: text.versions[0]?.text || ''}}/> : (
            text.versions[0]?.text || ''
        )}
            {user?.roles.includes(ROLES.EDITOR) && (
                <div className={'position-absolute'}
                     style={{top: editButtonPosition?.top || -25, right: editButtonPosition?.right || -32, zIndex: 10}}>

                    <Button shape={'circle'} color={'white'}
                            onClick={() => setEdit(true)}
                            icon={<EditOutlined/>} className={'mb-1'}/>
                </div>
            )}
        </>
    }

    return <>
        <Modal title={'Upravit'} width={800} open={edit} onCancel={() => setEdit(false)}
               styles={{body: {maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden'}}}
               classNames={{footer: 'border-top'}}
               footer={[
                   <Row key={'row'} justify={"end"} className={'mt-2'}>
                       <Col>
                           <Button onClick={() => setEdit(false)} className={'mr-2'}>Zrušiť</Button>
                           <Button onClick={() => form.validateFields().then(onSubmit)} type={"primary"}
                                   icon={<SaveOutlined/>} loading={saving}>Uložiť</Button>
                       </Col>
                   </Row>
               ]}>
            <Form layout={'vertical'} form={form} initialValues={text} className={'w-100'}>
                {versions.length > 1 ? <Tabs>
                    <Tabs.TabPane></Tabs.TabPane>
                </Tabs> : (
                    <Row className={'mt-2 w-100 p-1'}>
                        <Col className={'w-100'}>
                            <Form.Item hidden={true} name={['versions', 0, 'language']}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label={'Text'} name={['versions', 0, 'text']}
                                       rules={[{required: true, message: 'Políčko je vyžadováno'}, inputType === 'email' ? {
                                           type: 'email',
                                           message: 'Neplatny E-mail!',
                                       } : {}]}>
                                {{
                                    wysiwyg: <Wysiwyg/>,
                                    textarea: <Input.TextArea rows={3}/>,
                                    basic: <Input/>,
                                    email: <Input type={'email'}/>
                                }[inputType || 'basic']}
                            </Form.Item>
                        </Col>
                    </Row>
                )}
            </Form>
        </Modal>
        {wrap ? <div className={wrapClassName || ' position-relative w-max-content ' }>
            {renderText()}
        </div> : renderText()}
    </>
}

const mapStateToProps = ({setup}: IStore) => {
    const {editableTexts, user} = setup
    return {editableTexts, user}
}

const mapDispatchToProps = {
    update: (changes: ISetupStateUpdate) => update(changes)
}


export default connect(mapStateToProps, mapDispatchToProps)(EditableText);