import React, {FC, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, Dropdown} from "antd";
import {DownOutlined, EditOutlined} from '@ant-design/icons'
import {IStore} from "../../../redux/IStore";
import {connect} from "react-redux";
import {IUser, ROLES} from "../../../api/entity/security/IUser";

interface IProps {
    user?: IUser
}

const Header: FC<IProps> = ({user}) => {

    const [current, setCurrent] = useState('home');
    const [loading, setLoading] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);

    const navigate = useNavigate();

    const location = useLocation()

    useEffect(() => {
        setCurrent(location.pathname.substr(1))
    }, [location])

    const showPage = (url: string) => {
        setOpenMenu(false)
        navigate(url)
        setCurrent(url)
    }

    const menuItems = [
        {url: '', label: 'Domov'},
        {url: 'novinky', label: 'Novinky'},
        ...(user?.roles.includes(ROLES.EDITOR) ? [{url: 'sip', label: 'DOMY-SIP', chidlren: [
                {url: '', label: 'Domov'},
                {url: 'panel', label: 'Panel'},
                {url: 'katalog', label: 'Katalog'},
                {url: 'reference', label: 'Reference'},
            ]}] : []),
        {url: 'modulovy-domy', label: 'Modulové Domy'},
        {url: 'mobilni-domy', label: 'Mobilní domy'},
        {url: 'realizace', label: 'Realizace'},
        {url: 'proc-viva-house', label: 'Proč Viva House'},
        {url: 'technologie', label: 'Technologie'},
        {url: 'kontakt', label: 'Kontakt'}
    ]

    console.log(current)
    return <div>
        {/*Preload*/}
        <div className={"prague-loader" + (loading ? '' : ' is-loaded')}>
            <div className="prague-loader-wrapper">
                <div className="prague-loader-bar">Viva House</div>
            </div>
        </div>

        <header style={{backgroundColor: "black"}}
                className="prague-header simple sticky-menu sticky-mobile-menu light">
            {/*LOGO*/}
            <div className="prague-logo">
                <a href="/"> <img src="/img/logo.png" style={{width: "214px"}} className="image_logo"
                                  alt="logo"/></a>
            </div>

            <div className="prague-header-wrapper">
                <div className={"prague-navigation " + (openMenu ? 'open' : '')}>
                    <div className={"pargue-navigation-wrapper"} >
                        <div className="prague-navigation-inner">
                            <nav>
                                <ul className="main-menu">
                                    {menuItems.map(item => (
                                        <li key={item.url}
                                            className={"menu-item menu-item-type-custom menu-item-object-custom" + ((item.url === current || (item.url && current.includes(item.url))) && ' current-menu-item current_page_item')}>
                                            {item.chidlren ? <Dropdown menu={{items: item.chidlren.map(child => ({
                                                    key: child.url,
                                                    label: <Link className={current === (item.url + "/" + child.url) ? ' font-bold swiper-cube-shadow' : ''} onClick={e => {
                                                        e.preventDefault()
                                                        showPage(item.url + (child.url ? "/" + child.url : ''))
                                                    }} to={"/"}>{child.label}</Link>
                                                }))}}>
                                                <Link onClick={e => {
                                                    e.preventDefault()
                                                    showPage(item.url)
                                                }} to={"/" + item.url}>{item.label}<DownOutlined className={'ml-1'} /></Link>
                                            </Dropdown> : <Link onClick={e => {
                                                e.preventDefault()
                                                showPage(item.url)
                                            }} to={"/" + item.url}>{item.label}</Link>}

                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

                {/* mobile icon */}
                <div className={"prague-nav-menu-icon " + (openMenu ? 'active' : '')}>
                    <a onClick={() => setOpenMenu(!openMenu)}>
                        <i></i>
                    </a>
                </div>

                {/*    <!--SOCIAL
                // <div className="prague-social-nav">
                //   <a href="#">
                //     <i className="fa fa-chain-broken" aria-hidden="true"></i>
                //   </a>
                //
                //   <ul className="social-content">
                //     <li>
                //       <a target="_blank" href="https://www.facebook.com/zdravydom.com.s.r.o">
                //         <i aria-hidden="true" className="fa fa-facebook"></i>
                //       </a>
                //     </li>
                //   </ul>
                // </div>
                // SOCIAL-->
                */}
            </div>
        </header>
    </div>
}

const mapStateToProps = ({setup}: IStore) => {
    const {user} = setup
    return {user}
}



export default connect(mapStateToProps)(Header);