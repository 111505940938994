import {IModularHouse} from "../../entity/home/IModularHouse";
import ApiService, {IApiService} from "../ApiService";

const COLLECTION = 'modular-house'

interface IService extends IApiService<IModularHouse>{
    getHomepageCover: (modularHouses: IModularHouse[]) => IModularHouse | undefined
}

const ModularHouseService: IService = {
    list: (params) => {
        return ApiService.list(COLLECTION, params)
    },
    get: (id) => {
        return ApiService.get(COLLECTION, id)
    },
    create: (data) => {
        return ApiService.create(COLLECTION, data)
    },
    update: (id, data) => {
        return ApiService.update(COLLECTION, id, data)
    },
    delete: (id) => {
        return ApiService.delete(COLLECTION, id)
    },
    getHomepageCover: (modularHouses: IModularHouse[]) => {
        return modularHouses.some(h => h.onHomepageCover) ? modularHouses.sort((x, y) => {
            return (x.onHomepageCover || 0) - (y.onHomepageCover || 0);
        })[modularHouses.length - 1] : modularHouses[Math.floor(Math.random() * modularHouses.length)]
    }
}

export default ModularHouseService