import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {notification} from "antd";
import ApiService, {buildUrl} from "./ApiService";
import customHistory from "./router/CustomHistory";


const fetch = axios.create({

})

interface IRequest extends AxiosRequestConfig {

}

interface IResponse extends AxiosResponse {
    config: IRequest
}

export const TOKEN_KEY = '_AKey'

// Add a request interceptor
fetch.interceptors.request.use(function (config: IRequest) {
    const token = localStorage.getItem(TOKEN_KEY)
    config.headers = config.headers || {}
    if (token){
        config.headers.Token = token
    }
    return config;
});


fetch.interceptors.response.use(function (response: IResponse) {
    return response
}, (error) => {

    let message = ''
    if (error.response) {

        if (error.response.status === 400) {
            message = 'Invalid request'
        }

        if (error.response.status === 401) {
            notification.warning({message: 'Přihlášení vypršelo, prosím přihlašte se znovu'})
            localStorage.removeItem(TOKEN_KEY)
            customHistory.replace("/login")
            return Promise.reject(error.response)
        }

        if (error.response.status === 403) {
            //history.push(NO_ACCESS_ROUTE)

            return Promise.reject(error)
        }

        if (error.response.status === 404) {
            message = 'Not Found'
        }

        if (error.response.status === 406) {
            return Promise.reject(error.response)
        }

        if (error.response.status === 500) {
            message = 'Internal Server Error'
        }

        if (error.response.status === 508) {
            message = 'Time Out'
        }

    } else {
        message = 'No response'
    }

    notification.error({message: 'Chyba!', description: message})

    return Promise.reject(error);
});

export default fetch