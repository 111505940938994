import React, {useEffect} from 'react';
import './App.sass';
import {Route, Routes} from "react-router-dom";
import {IStore} from "./redux/IStore";
import {load, logout} from "./redux/actions/Setup";
import {connect} from "react-redux";
import HomeIndex from "./app/home";
import useExternalScripts from "./shared/appendScriptTag";
import Loading from "./shared/loading/Loading";
import LoginIndex from "./app/login";
import {ConfigProvider, FloatButton, Popover} from "antd";
import {InfoOutlined, LogoutOutlined, UserOutlined} from "@ant-design/icons";
import cs_Cz from 'antd/locale/cs_CZ';
import {IUser} from "./api/entity/security/IUser";
import ScrollToTop from "./shared/structure/ScrollToTop";
import CustomRouter from "./api/service/router/CustomRouter";
import customHistory from "./api/service/router/CustomHistory";

interface IProps {
    loading: boolean,
    load: () => void,
    logout: () => void,
    user?: IUser
}

const App: React.FC<IProps> = ({loading, load, user, logout}) => {
    useExternalScripts("/js/jquery.js")
    useExternalScripts("/js/swiper.min.js")
    useExternalScripts("/js/isotope.pkgd.min.js")
    useExternalScripts("/js/hammer.min.js")
    useExternalScripts("/js/foxlazy.min.js")
    useExternalScripts("/js/all.js")
    useExternalScripts("https://maps.googleapis.com/maps/api/js?key=AIzaSyDqH-YPOECxBBvFpBoeW3SMsGq2i4lG-kY&language=en")
    useExternalScripts("/js/google_map.js")
    useExternalScripts("/js/jquery.magnific-popup.min.js")

    useEffect(() => {
        load()
    }, []);

    return <div className="App">
        <ConfigProvider locale={cs_Cz}>
            <CustomRouter history={customHistory}>
                <Loading loading={loading}>
                </Loading>
                <ScrollToTop>
                    <Routes>
                        <Route path="/login" element={<LoginIndex/>} />
                        <Route path="/*" element={<HomeIndex/>}/>
                    </Routes>
                </ScrollToTop>
            </CustomRouter>

            {user &&
                <FloatButton.Group
                    trigger="hover"
                    type="primary"
                    style={{right: 24, zIndex: 1000}}
                    icon={<UserOutlined/>}
                >
                    <FloatButton onClick={logout} tooltip={'Odhlásit se'} icon={<LogoutOutlined/>}/>
                    <Popover trigger={['click']} content={user.email}><FloatButton icon={<InfoOutlined/>}/></Popover>
                </FloatButton.Group>
            }
        </ConfigProvider>
    </div>

}


const mapStateToProps = ({setup}: IStore) => {
    const {loading, user} = setup
    return {loading, user}
};

const mapDispatchToProps = {
    load: () => load(),
    logout: () => logout()
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(App));
