import {Alert, Button, Checkbox, Col, Form, Input, Row} from "antd";
import ContactFormService from "../../../api/service/home/ContactFormService";
import React, {FC, useState} from "react";
import EditableImage from "./component/EditableImage";
import EditableText from "./component/EditableText";
import ReactDOMServer from "react-dom/server";
import {IStore} from "../../../redux/IStore";
import {ISetupStateUpdate} from "../../../redux/reducers/Setup";
import {update} from "../../../redux/actions/Setup";
import {connect} from "react-redux";
import {IEditableText} from "../../../api/entity/home/IEditableText";
import {IUser} from "../../../api/entity/security/IUser";

interface IProps {
    editableTexts: IEditableText[]
}

const Contact: FC<IProps> = ({editableTexts}) => {

    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState<boolean>();
    const [message, setMessage] = useState<string>();

    const phone = editableTexts.find(m => m.id === 'contact.phone')
    const email = editableTexts.find(m => m.id === 'contact.email')

    return <div>
        <div className="container-fluid no-padd">
            <div className="row-fluid no-padd">
                <div className="col-sm-12 no-padd">
                    <EditableImage className={'h-100'} id={'contact.cover'}
                                   editButtonPosition={{top: 50, right: 20}}
                                   fallbackUrl={'img/kontakt.jpg'}
                                   imageRender={(url: string) => (
                                       <div
                                           className="container-fluid top-banner no-padd big light no-marg-bottom vindow-height"
                                           style={{
                                               background: 'url(' + url + ')',
                                               backgroundPosition: 'center',
                                               backgroundSize: 'cover'
                                           }}>
                                           <span className="overlay"></span>
                                           <div className="content">
                                               <div className="prague-svg-animation-text"></div>
                                               <h1 className="title">Kontakt</h1>
                                           </div>
                                           <div className="top-banner-cursor"></div>
                                       </div>
                                   )}/>

                </div>
            </div>
        </div>

        <div className="container no-padd margin-lg-75t margin-sm-30t margin-md-0t">
            <div className="row-fluid">
                <div
                    className="margin-md-90t margin-sm-20t col-sm-12 col-lg-offset-0 col-lg-6 col-md-offset-0 col-md-6 col-sm-offset-0 col-xs-12 padd-only-xs">
                    <div className="heading left dark">
                        <div className="subtitle">KONTAKT</div>
                        <h2 className="title">Fakturační údaje</h2>
                        <div className="content">
                            <p>
                                Sídlo: Bečovská 1648, 104 00 Praha 10<br/>
                                Česká republika<br/>
                            </p>
                        </div>
                    </div>
                    <Row className="row-fluid vc_custom_1488483852815">
                        <Col xs={12} sm={8} className=" no-padd no-padd">
                            <div className="adddress-block">
                                <div className="address-block-outer">
                                    <span className="separator"></span>

                                    <h4 className="address-title">EMAIL / JMÉNO</h4>

                                    <p>
                                        <a className="kontaktsmall-email position-relative"
                                           href={"mailto:" + email?.versions[0]?.text || 'info@vivahouse.eu'}>
                                            <EditableText inputType={'email'} id={'contact.email'} fallback={'info@vivahouse.eu'}/></a><br/>
                                        <a className="kontaktsmall-email position-relative"><EditableText id={'contact.name'} fallback={'Ing. Monika Vavreková'}/></a>
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={4} className=" no-padd">
                            <div className="adddress-block">
                                <div className="address-block-outer">
                                    <span className="separator"></span>

                                    <h4 className="address-title">TEL.Č.</h4>
                                    <a className="kontaktsmall position-relative"
                                       href={'tel:+420' + (phone?.versions[0]?.text || '737 255 647').replaceAll(' ', '')}>
                                        <EditableText id={'contact.phone'} fallback={'737 255 647'}/></a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="col-sm-12 col-md-6 col-xs-12 margin-lg-90t margin-sm-minus margin-md-190t no-padd">
                    <h2 className="d-flex justify-content-center title">Napište nám</h2>
                    <div className="vc_column-inner vc_custom_1488483852815">
                        <div className="prague-formidable vc_formidable">
                            <div className="frm_forms with_frm_style frm_style_formidable-style-2-2"
                                 id="frm_form_3_container">
                                <Form className="contact-form" onFinish={values => {
                                    setSending(true)
                                    setMessage(undefined)
                                    ContactFormService.send(values).then(result => {
                                        setMessage(result.message)
                                        setSuccess(result.success)
                                        setSending(false)
                                    })
                                }}>
                                    <Form.Item name={'name'} className={'mb-3'}
                                               rules={[{required: true, message: 'Vyplňte prosím'}]}>
                                        <Input className={'input border-radius-10'} placeholder={'Jméno:'}/>
                                    </Form.Item>
                                    <Form.Item name={'email'} className={'mb-3'}
                                               rules={[{required: true, message: 'Vyplňte prosím'}]}>
                                        <Input className={'input border-radius-10'} placeholder={'Email:'}/>
                                    </Form.Item>
                                    <Form.Item name={'phone'} className={'mb-3'}>
                                        <Input className={'input border-radius-10'} placeholder={'Tel.č.:'}/>
                                    </Form.Item>
                                    <Form.Item name={'message'} className={'mb-3'}
                                               rules={[{required: true, message: 'Vyplňte prosím'}]}>
                                        <Input.TextArea cols={20} className={'input border-radius-10'}
                                                        placeholder={'Zpráva:'}/>
                                    </Form.Item>
                                    <Form.Item name={'gdpr'}
                                               rules={[{required: true, message: 'Souhlaste prosím s GDPR.'}]}
                                               valuePropName={'checked'}>
                                        <Checkbox rootClassName={'d-flex align-items-baseline no-wrap'}>
                                            <span style={{lineHeight: 'unset'}}>Souhlasím se
                                                zpracováním <a
                                                    style={{color: "rgb(34, 34, 238)", textDecoration: "underline"}}
                                                    target="_blank" href="/docs/Ochrana osobních údajů.pdf">osobních
                                                    údajů</a> a <a target="_blank" style={{
                                                    color: "rgb(34, 34, 238)",
                                                    textDecoration: "underline"
                                                }} href="/docs/GDPR dokument.pdf">GDPR</a></span>
                                        </Checkbox>
                                    </Form.Item>
                                    {message && (
                                        <Alert type={success === false ? 'error' : 'success'} description={message}>
                                        </Alert>
                                    )}
                                    <Button
                                        htmlType="submit"
                                        name="submit"
                                        loading={sending}
                                        value="Submit"
                                        className="submit-button border-radius-10 send-email viva-button"
                                        id="submit_btn2"
                                    >
                                        Odeslat
                                    </Button>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                {/*
                                <!--
                <div className="col-sm-12 col-md-6 col-xs-12 margin-lg-170t margin-sm-40t margin-md-190t no-padd">
                  <div className="vc_column-inner vc_custom_1488483852815">
                    <div className="prague-formidable vc_formidable">
                      <div className="frm_forms with_frm_style frm_style_formidable-style-2-2" id="frm_form_3_container">
                        <form enctype="multipart/form-data" method="post" className="frm-show-form contact-us-form" id="form_jgd6s">
                          <div className="frm_form_fields">
                            <fieldset>
                              <div className="frm_fields_container">
                                <input type="hidden" name="frm_action" value="create" />
                                <input type="hidden" name="form_id" value="3" />
                                <input type="hidden" name="frm_hide_fields_3" id="frm_hide_fields_3" value="" />
                                <input type="hidden" name="form_key" value="jgd6s" />
                                <input type="hidden" name="item_meta[0]" value="" />
                                <input type="hidden" id="frm_submit_entry_3" name="frm_submit_entry_3" value="ff0424ceb3" /><input type="hidden" name="_wp_http_referer" value="/onepage-home/" />
        
                                <div className="row">
                                  <div className="col-md-6 col-sm-6">
                                    <div id="frm_field_15_container" className="frm_form_field form-field frm_required_field frm_none_container">
                                      <input type="text" id="field_tgfdg" name="item_meta[15]" value="" placeholder="Jméno" data-reqmsg="This field cannot be blank." data-invmsg="Name is invalid" />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <div id="frm_field_16_container" className="frm_form_field form-field frm_none_container">
                                      <input type="text" id="field_4bs9q" name="item_meta[16]" value="" placeholder="Tel.č." data-invmsg="Phone is invalid" />
                                    </div>
                                  </div>
                                </div>
                                <div id="frm_field_17_container" className="frm_form_field form-field frm_required_field frm_none_container">
                                  <input type="email" id="field_1ghyp" name="item_meta[17]" value="" placeholder="email@email.com" data-reqmsg="This field cannot be blank." data-invmsg="Email Address is invalid" />
                                </div>
                                <div id="frm_field_18_container" className="frm_form_field form-field frm_none_container">
                                  <textarea name="item_meta[18]" id="field_jy2v2" rows="5" placeholder="Zpráva" data-invmsg="Paragraph Text is invalid"></textarea>
                                </div>
                                <input type="hidden" name="item_key" value="" />
                                <div className="frm_submit">
                                  <input type="submit" value="Send" />
                                  <span className="arrow-right"></span>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                -->
                */}
            </div>
        </div>
    </div>
}

const mapStateToProps = ({setup}: IStore) => {
    const {editableTexts} = setup
    return {editableTexts}
}



export default connect(mapStateToProps)(Contact);