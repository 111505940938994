import {Button, Col, Form, Modal, Row} from "antd";
import {EditOutlined, SaveOutlined} from '@ant-design/icons'
import React, {FC, useEffect, useState} from "react";
import FileUpload from "../../../../shared/input/FileUpload";
import FileService from "../../../../api/service/FileService";
import {UploadFileStatus} from "antd/es/upload/interface";
import {IFile} from "../../../../api/entity/file/IFile";
import {IEditableImage} from "../../../../api/entity/home/IEditableImage";
import EditableImageService from "../../../../api/service/home/EditableImageService";
import {IStore} from "../../../../redux/IStore";
import {connect} from "react-redux";
import {ISetupStateUpdate} from "../../../../redux/reducers/Setup";
import {update} from "../../../../redux/actions/Setup";
import {IUser, ROLES} from "../../../../api/entity/security/IUser";

interface IProps {
    editableImages: IEditableImage[]
    imageRender: (url: string, image: IEditableImage) => JSX.Element
    fallbackUrl?: string
    update: (changes: ISetupStateUpdate) => void
    editButtonPosition?: { top: number | string, right: number | string }
    user?: IUser
    id: string
    className?: string
}

const EditableImage: FC<IProps> = ({
                                       id,
                                       imageRender,
                                       fallbackUrl,
                                       editableImages,
                                       update,
                                       user,
                                       editButtonPosition,
                                       className
                                   }) => {

    const [form] = Form.useForm();

    const [saving, setSaving] = useState(false);
    const [edit, setEdit] = useState(false);
    const [image, setImage] = useState<IEditableImage>({id: id, file: {name: '', uid: '', url: fallbackUrl}})

    useEffect(() => {
        const image = editableImages.find(m => m.id === id)
        image && setImage({...image, file: image.file.uid ? image.file : {name: '', uid: '', url: fallbackUrl}})
    }, [editableImages]);


    const onSubmit = async (values?: IEditableImage) => {
        let file: IFile | undefined = undefined
        setSaving(true)
        const newImage = values?.file?.uid !== image.file.uid
        let data = {...image, ...values}
        if (newImage) {
            const formData = new FormData()
            if (values?.file?.originFileObj) {
                formData.append('files[]', values.file.originFileObj, values.file.name)
                await FileService.upload?.(formData).then(results => {
                    file = results[0]
                    data = {
                        ...data,
                        file: file && file.label === values.file.name ? {
                            name: file.label,
                            uid: file.id,
                            status: 'done' as UploadFileStatus
                        } : image.file
                    }
                })
            } else {
                data = {...data, file: {name: '', uid: '', url: fallbackUrl}}
            }
        }
        EditableImageService.create({
            ...data,
            id: id
        }).then((result: IEditableImage) => {
            const images = [...editableImages]
            const index = images.findIndex(h => h.id === result?.id)
            if (index > -1) {
                images[index] = result
            } else {
                images.push(result)
            }
            update({editableImages: images})
            setSaving(false)
            setEdit(false)
        })
    }

    return <div className={'position-relative '  + (className || '')}>
        <Modal destroyOnClose={true} title={'Upravit'} width={800} open={edit} onCancel={() => setEdit(false)}
               styles={{body: {maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden'}}}
               classNames={{footer: 'border-top'}}
               footer={[
                   <Row key={'row'} justify={"end"} className={'mt-2'}>
                       <Col>
                           <Button onClick={() => setEdit(false)} className={'mr-2'}>Zrušiť</Button>
                           <Button onClick={() => form.validateFields().then(onSubmit)} type={"primary"}
                                   icon={<SaveOutlined/>} loading={saving}>Uložiť</Button>
                       </Col>
                   </Row>
               ]}>
            <Form layout={'vertical'} form={form} initialValues={{...image, file: image.file.uid ? image.file : undefined}}
                  className={'w-100'}>
                <Row className={'mt-2 w-100 p-1'}>
                    <Col className={'w-100'}>
                        <Form.Item label={'Obrázek'} name={'file'}
                                   rules={fallbackUrl ? [] : [{required: true, message: 'Políčko je vyžadováno'}]}>
                            <FileUpload accept={['image/*']}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
        {imageRender(image.file.uid ? FileService.preview(image.file.uid) : image.file.url || '', image)}
        {user?.roles.includes(ROLES.EDITOR) && (
            <div className={'position-absolute'}
                 style={{top: editButtonPosition?.top || 25, right: editButtonPosition?.right || 25, zIndex: 290}}>
                <Button shape={'circle'} color={'white'}
                        onClick={() => setEdit(true)}
                        icon={<EditOutlined/>} className={'mb-1'}/>
            </div>
        )}
    </div>
}

const mapStateToProps = ({setup}: IStore) => {
    const {editableImages, user} = setup
    return {editableImages, user}
}

const mapDispatchToProps = {
    update: (changes: ISetupStateUpdate) => update(changes)
}


export default connect(mapStateToProps, mapDispatchToProps)(EditableImage);