import ApiService, {IApiService} from "../ApiService";
import {IRecordsOrder} from "../../entity/home/IRecordsOrder";

const COLLECTION = 'records-order'

export const MAIN_PAGE_ORDER = 'main'
export const NEWS_PAGE_ORDER = 'news'
export const HOME_PAGE_ORDER = 'home'
export const REALIZATION_ORDER = 'realization'
export const SIP_HOUSE_ORDER = 'sip'
export const SIP_HOUSE_HOME_ORDER = 'sip-home'
export const SIP_REALIZATION_ORDER = 'sip-realization'

const RecordsOrderService: IApiService<IRecordsOrder> = {
    list: (params) => {
        return ApiService.list(COLLECTION, params)
    },
    get: (id) => {
        return ApiService.get(COLLECTION, id)
    },
    create: (data) => {
        return ApiService.create(COLLECTION, data)
    },
    update: (id, data) => {
        return ApiService.update(COLLECTION, id, data)
    },
    delete: (id) => {
        return ApiService.delete(COLLECTION, id)
    }
}

export default RecordsOrderService