import React, {useCallback, useState} from 'react';
import {
    DndContext,
    DragEndEvent,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
    DragOverlay,
    DragStartEvent
} from '@dnd-kit/core';
import {
    arrayMove,
    horizontalListSortingStrategy,
    rectSortingStrategy,
    SortableContext,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';

type SortMode = 'vertical' | 'horizontal' | 'grid'

interface IProps {
    ids: string[]
    onSort?: (ids: string[]) => void
    mode: SortMode
    renderOverlay?: (id: string) => JSX.Element
}

const SortableContainer: React.FC<IProps> = ({onSort, mode, ids, children, renderOverlay}) => {
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    const [activeId, setActiveId] = useState<string | null>(null);

    const handleDragStart = useCallback((event: DragStartEvent) => {
        setActiveId(event.active.id + '');
    }, []);
    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const {active, over} = event;

        if (over && active.id !== over.id) {
            const oldIndex = ids.indexOf(active.id + '');
            const newIndex = ids.indexOf(over!.id + '');

            return onSort?.(arrayMove(ids, oldIndex, newIndex));
        }

        setActiveId(null);
    }, [ids, onSort]);
    const handleDragCancel = useCallback(() => {
        setActiveId(null);
    }, []);

    return (
        <DndContext sensors={sensors} onDragEnd={handleDragEnd} onDragStart={handleDragStart}
                    onDragCancel={handleDragCancel}>
            <SortableContext items={ids} strategy={{
                vertical: verticalListSortingStrategy,
                horizontal: horizontalListSortingStrategy,
                grid: rectSortingStrategy
            }[mode]}>
                {children}
                {renderOverlay && <DragOverlay adjustScale style={{transformOrigin: '0 0 '}}>
                    {activeId ? renderOverlay(activeId) : <div>5</div>}
                </DragOverlay>}

            </SortableContext>
        </DndContext>
    );
};

export default SortableContainer


