import {buildUrl} from "../ApiService";
import fetch, {TOKEN_KEY} from "../FetchInterceptor";
import {reLoad, update} from "../../../redux/actions/Setup";


const COLLECTION = 'token'

const TokenService = {
    load: (email: string, password: string): Promise<string | false> => {
        return fetch.post(buildUrl(COLLECTION), {email, password}).then(response => {
            const token = response.data[TOKEN_KEY]
            if (token) {
                localStorage.setItem(TOKEN_KEY, response.data[TOKEN_KEY])
            }

            return token
        })
    },
    remove: () => {
        localStorage.removeItem(TOKEN_KEY)
    }
}

export default TokenService