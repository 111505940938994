import {Alert, Button, Col, Popconfirm, Row} from "antd";
import {DeleteOutlined, DragOutlined, EditOutlined, EyeInvisibleOutlined} from '@ant-design/icons'
import React, {FC, useEffect, useState} from "react";
import {IModularHouse} from "../../../api/entity/home/IModularHouse";
import ModularForm from "./form/ModularForm";
import {IStore} from "../../../redux/IStore";
import {connect} from "react-redux";
import {update} from "../../../redux/actions/Setup";
import ModularHouseService from "../../../api/service/home/ModularHouseService";
import {ISetupState, ISetupStateUpdate} from "../../../redux/reducers/Setup";
import SortableItem from "../../../shared/structure/sortable/SortableItem";
import {Link} from "react-router-dom";
import SortableContainer from "../../../shared/structure/sortable/SortableContainer";
import RecordsOrderService, {NEWS_PAGE_ORDER} from "../../../api/service/home/RecordsOrderService";
import {IRecordsOrder} from "../../../api/entity/home/IRecordsOrder";
import {IUser, ROLES} from "../../../api/entity/security/IUser";
import FileService from "../../../api/service/FileService";
import EditableText from "./component/EditableText";


interface IProps {
    modularHouses: IModularHouse[],
    recordsOrder: IRecordsOrder[]
    update: (changes: ISetupStateUpdate) => void,
    user?: IUser
}

const News: FC<IProps> = ({modularHouses, update, recordsOrder, user}) => {

    const [editHouse, setEditHouse] = useState<IModularHouse>();

    useEffect(() => {
        let result: IModularHouse[] = []

        const news = recordsOrder.find(m => m.id === NEWS_PAGE_ORDER)
        if (news) {
            modularHouses.forEach(function (a) {
                if (news.order.indexOf(a.id) > -1) {
                    result[news.order.indexOf(a.id)] = a
                }
            });
            modularHouses.forEach(function (a) {
                if (news.order.indexOf(a.id) === -1) {
                    result.push(a)
                }
            });
            update({modularHouses: result})
        }

    }, [recordsOrder, editHouse]);

    const onFinish = (house?: IModularHouse) => {
        setEditHouse(undefined)
        if (house) {
            const houses = [...modularHouses]
            const index = houses.findIndex(h => h.id === house?.id)
            if (index > -1) {
                houses[index] = house
            } else {
                houses.push(house)
            }
            update({modularHouses: houses})
        }
    }

    const onDragEnd = (ids: string[]) => {
        let result: IModularHouse[] = []

        modularHouses.forEach(function (a) {
            result[ids.indexOf(a.id)] = a
        });
        RecordsOrderService.create({id: NEWS_PAGE_ORDER, order: result.map(h => h.id)}).then(order => {
            let temp = [...recordsOrder]
            const index = temp.findIndex(o => o.id === order.id)
            temp[index] = order
            update({recordsOrder: temp})
        })

        update({modularHouses: result})
    }

    function remove(house: IModularHouse) {
        ModularHouseService.delete(house.id).then(() => {
            update({modularHouses: modularHouses.filter(h => h.id !== house.id)})
        })
    }

    function renderHouse(ref: (node: (HTMLElement | null)) => void, styles: React.CSSProperties, isDragging: boolean, house: IModularHouse, props: any) {
        return <Col xs={24} sm={12} lg={8} ref={ref}
                    style={{...styles, maxWidth: '380px'}}
                    key={house.images[0]?.name}
                    className={isDragging ? 'is-dragging p-0' : ''}>
            <div className="project-grid-wrapper">
                <Link className="project-grid-item-img-link"
                      to={"/modulovy-domy/" + encodeURI(house.name.replaceAll(' ', '_'))}>
                    <div className="project-grid-item-img">
                        {house.isPublic === false && <div className="overlay z-index-1">
                            <Alert icon={<EyeInvisibleOutlined style={{color: 'black'}}/>}
                                   style={{backgroundColor: '#c7c7c7'}} message="Neveřejný" banner/>
                        </div>}
                        <img src={FileService.preview(house.images[0]?.uid || '')}
                             className="s-img-switch wp-post-image" alt={house.name}/>
                    </div>
                </Link>

                <div className="project-grid-item-content">
                    <Row justify={"center"} align={'middle'}>
                        <Col>
                            <h4 className="project-grid-item-title">
                                <Link className={'text-uppercase'}
                                      to={"/modulovy-domy/" + encodeURI(house.name.replaceAll(' ', '_'))}>{house.name}</Link>
                            </h4>
                            <div
                                className="project-grid-item-category">{Number(house.price || 0).toLocaleString()} Kč
                            </div>
                        </Col>
                    </Row>
                    {user?.roles.includes(ROLES.EDITOR) && (
                        <div className={'position-absolute'} style={{top: 5, right: 5, zIndex: 1}}>
                            <Col className={'flex flex-column p-0'}>
                                <Button shape={'circle'}
                                        onClick={() => setEditHouse(house)}
                                        icon={<EditOutlined/>} className={'mb-1'}/>
                                <Button shape={'circle'}  {...props} style={{cursor: 'move'}}
                                        icon={<DragOutlined/>} className={'mb-1'}/>
                                <Popconfirm onConfirm={() => remove(house)} title={'Opravdu smazat?'}>
                                    <Button danger={true} shape={'circle'} icon={<DeleteOutlined/>}/>
                                </Popconfirm>
                            </Col>
                        </div>
                    )}
                </div>
            </div>
        </Col>
    }

    const houses = modularHouses.filter(h => (user?.roles.includes(ROLES.EDITOR) || h.isPublic !== false) && h.isNew)
    return <div className="container no-padd margin-lg-145t margin-lg-145b margin-sm-100t margin-sm-70b">
        {!!editHouse && (
            <ModularForm onFinish={onFinish} house={editHouse}/>
        )}
        <Row justify={"space-around"} align={'middle'}>
            <h1 className="d-flex justify-content-center mb-2 title">Novinky</h1>
        </Row>
        <div className={'d-flex justify-content-center mb-2 text-center position-relative'}>
            <EditableText id={'modular-houses-news.description'} inputType={'wysiwyg'} fallback={
                <p>
                    Modulové dřevostavby
                    jsou moderním, cenově dostupným řešením bydlení. Všechny domy jsou vyhotoveny z kvalitních materiálů
                    v
                    nízkoenergetickém standardu. Poskytují zdravé prostředí a dokonalý komfort, který uspokojí i
                    náročného
                    klienta
                </p>
            }/>
        </div>
        {houses.length === 0 && (
            <h2 className={'text-center'}>
                <EditableText id={'modular-houses-news.empty'} inputType={'basic'} fallback={
                    'Připravujeme pro vás novinky'
                }/>
            </h2>
        )}
        <div className="row-fluid">
            <div className="col-sm-12 no-padd">
                <div data-unique-key="d30797fabd788147235e48099dfaacc0" className="js-load-more" data-start-page="1"
                     data-max-page="3">
                    <Row gutter={[32, 20]} justify={"space-around"} className={'w-100 m-0'}>
                        <SortableContainer onSort={onDragEnd} ids={houses.map(h => h.id) || []} mode={'grid'}
                                           renderOverlay={id => {
                                               const draggedHouse = houses.find(h => h.id === id)
                                               return draggedHouse ? <SortableItem key={draggedHouse.id}
                                                                                   render={(styles, ref, isDragging, props) => renderHouse(ref, styles, isDragging, draggedHouse, props)}
                                                                                   id={draggedHouse.id}/> : <></>
                                           }}>
                            {houses.map(house => (
                                <SortableItem key={house.id}
                                              render={(styles, ref, isDragging, props) => renderHouse(ref, styles, isDragging, house, props)}
                                              id={house.id}/>
                            ))}
                        </SortableContainer>
                    </Row>
                </div>
            </div>
        </div>
    </div>
}


const mapStateToProps = ({setup}: IStore) => {
    const {modularHouses, recordsOrder, user} = setup
    return {modularHouses, recordsOrder: recordsOrder, user}
};

const mapDispatchToProps = {
    update: (changes: { [key in keyof ISetupState]?: ISetupState[key] }) => update(changes),
}


export default connect(mapStateToProps, mapDispatchToProps)(News);