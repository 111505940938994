import {Link} from "react-router-dom";
import VivaIcons from "../../../shared/VivaIcons";
import React, {FC, useEffect, useState} from "react";
import SortableContainer from "../../../shared/structure/sortable/SortableContainer";
import {Button, Col, Row} from "antd";
import {IStore} from "../../../redux/IStore";
import {ISetupStateUpdate} from "../../../redux/reducers/Setup";
import {update} from "../../../redux/actions/Setup";
import {connect, ConnectedProps} from "react-redux";
import SortableItem from "../../../shared/structure/sortable/SortableItem";
import {IModularHouse} from "../../../api/entity/home/IModularHouse";
import {IRecordsOrder} from "../../../api/entity/home/IRecordsOrder";
import RecordsOrderService, {HOME_PAGE_ORDER} from "../../../api/service/home/RecordsOrderService";
import ModularHouseService from "../../../api/service/home/ModularHouseService";
import {DragOutlined, EditOutlined} from "@ant-design/icons";
import {IUser, ROLES} from "../../../api/entity/security/IUser";
import EditableImage from "./component/EditableImage";
import EditableText from "./component/EditableText";
import ModularCoverForm from "./form/ModularCoverForm";
import {IEditableImage} from "../../../api/entity/home/IEditableImage";
import FileService from "../../../api/service/FileService";

const mapStateToProps = ({setup}: IStore) => {
    const {modularHouses, recordsOrder, user, loading} = setup
    return {modularHouses, recordsOrder, user, loading}
};

const mapDispatchToProps = {
    update: (changes: ISetupStateUpdate) => update(changes)
}

const connector = connect(mapStateToProps, mapDispatchToProps);

// Extract "the type of the props passed down by connect"
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = {
    modularHouses: IModularHouse[],
    recordsOrder: IRecordsOrder[]
    update: (changes: ISetupStateUpdate) => void,
    user?: IUser
    loading: boolean
} & PropsFromRedux

const Home: FC<IProps> = ({modularHouses, update, recordsOrder, user, loading}) => {

    const [editCoverHouseLink, setEditCoverHouseLink] = useState(false);
    const [animateButton, setAnimateButton] = useState(false);

    useEffect(() => {
        !loading && setTimeout(() => setAnimateButton(true), 500)
    }, [loading]);

    useEffect(() => {
        let result: IModularHouse[] = []

        const home = recordsOrder.find(m => m.id === HOME_PAGE_ORDER)
        if (home) {
            modularHouses.forEach(function (a) {
                if (home.order.indexOf(a.id) > -1) {
                    result[home.order.indexOf(a.id)] = a
                }
            });
            modularHouses.forEach(function (a) {
                if (home.order.indexOf(a.id) === -1) {
                    result.push(a)
                }
            });
            update({modularHouses: result})
        }
    }, [recordsOrder]);


    const onDragEnd = (ids: string[]) => {
        let result: IModularHouse[] = []

        modularHouses.forEach(function (a) {
            if (ids.indexOf(a.id) > -1) {
                result[ids.indexOf(a.id)] = a
            }
        });
        modularHouses.forEach(function (a) {
            if (ids.indexOf(a.id) === -1) {
                result.push(a)
            }
        });
        RecordsOrderService.create({id: HOME_PAGE_ORDER, order: result.filter(h => h.onHomepage).map(h => h.id)}).then(order => {
            let temp = [...recordsOrder]
            const index = temp.findIndex(o => o.id === order.id)
            temp[index] = order
            update({recordsOrder: temp})
        })

        update({modularHouses: result})
    }

    const formatPrice = (amount: number) => {
        let formatter = new Intl.NumberFormat()
        return formatter.format(amount)
    }

    const onFinish = (house?: IModularHouse) => {
        setEditCoverHouseLink(false)
        if (house) {
            const houses = [...modularHouses]
            const index = houses.findIndex(h => h.id === house?.id)
            if (index > -1) {
                houses[index] = house
            } else {
                houses.push(house)
            }
            update({modularHouses: houses})
        }
    }

    const currentCoverHouse = ModularHouseService.getHomepageCover([...modularHouses])

    function renderHouse(ref: (node: (HTMLElement | null)) => void, styles: React.CSSProperties, isDragging: boolean, house: IModularHouse, props: any) {
        return <Col xs={24} sm={12} lg={8} ref={ref}
                    style={{...styles, maxWidth: '380px'}}
                    className={isDragging ? 'is-dragging p-0' : ''}>
            <div className="project-grid-wrapper">
                <Link className="project-grid-item-img-link"
                      to={"/modulovy-domy/" + encodeURI(house.name.replaceAll(' ', '_'))}>
                    <div className="project-grid-item-img">
                        <img src={FileService.preview(house.images[0]?.uid || '')}
                             className="s-img-switch wp-post-image"
                             alt={house.name}/>
                    </div>
                </Link>

                <div className="project-grid-item-content">
                    <Row justify={"space-between"} align={'middle'}>
                        <div></div>
                        <Col>
                            <h4 className="project-grid-item-title text-capitalize">
                                <Link className={'text-uppercase'}
                                      to={"/modulovy-domy/" + encodeURI(house.name.replaceAll(' ', '_'))}>{house.name}</Link>
                            </h4>
                            <div
                                className="project-grid-item-category">{formatPrice(house.price || 0)} Kč
                            </div>
                        </Col>
                        <Col className={'flex flex-column'}>
                            {user?.roles.includes(ROLES.EDITOR) && (
                                <Button shape={'circle'}  {...props} style={{cursor: 'move'}}
                                        icon={<DragOutlined/>} className={'mb-1'}/>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        </Col>;
    }


    const onlyHomePageHouses = modularHouses.filter(h => h.onHomepage)

    return <div>
        {editCoverHouseLink && (
            <ModularCoverForm onFinish={onFinish}/>
        )}
        <div className="container-fluid no-padd">
            <div>
                <div>
                    <EditableImage editButtonPosition={{top: 110, right: 20}} id={'homepage.cover.image'}
                                   imageRender={(url: string) => (
                                       <div className="container-fluid top-banner no-padd big fullheight light" style={{
                                           backgroundImage: 'url(' + url + ')',
                                           backgroundPosition: 'center',
                                           backgroundSize: 'cover',
                                           backgroundRepeat: "no-repeat"
                                       }}>
                                           <span className="overlay"></span>
                                           <Row className="content flex-column" align={'middle'}>
                                               <div className="prague-svg-animation-text"></div>
                                               <div className="subtitle w-max-content position-relative">
                                                   <EditableText id={'home.cover.small.title'}
                                                                 fallback={'Novinka LYON'}/>
                                               </div>
                                               <h1 className="title display-linebreak position-relative w-max-content">
                                                   <EditableText editButtonPosition={{right: 20, top: -30}}
                                                                 id={'home.cover.big.title'} inputType={'textarea'}>Viva
                                                       House přináší
                                                       modulové a mobilní domy
                                                   </EditableText>
                                               </h1>
                                               <div className={'position-relative'} style={{marginTop: 40}}>
                                                   <Link
                                                       to={"modulovy-domy/" + encodeURI(currentCoverHouse?.name.replaceAll(' ', '_') || '')}
                                                       className={"a-btn creative mt-0" + (animateButton ? " anima" : '')}
                                                       target="_self">
                                                       <span className="a-btn-line"></span>
                                                       ZOBRAZIT PROJEKT</Link
                                                   >
                                                   {user?.roles.includes(ROLES.EDITOR) && (
                                                       <div className={'position-absolute'} style={{
                                                           top: -25,
                                                           right: -32,
                                                           zIndex: 290
                                                       }}>
                                                           <Button shape={'circle'} color={'white'}
                                                                   onClick={() => setEditCoverHouseLink(true)}
                                                                   icon={<EditOutlined/>} className={'mb-1'}/>
                                                       </div>
                                                   )}
                                               </div>
                                           </Row>
                                           <div className="top-banner-cursor"></div>
                                       </div>
                                   )}/>
                </div>
            </div>
        </div>

        <div className="container no-padd">
            <div className="row-fluid margin-lg-70t">
                <div
                    className="column col-sm-10 col-lg-6 col-md-offset-0 col-md-6 col-sm-offset-1 col-xs-12 margin-lg-65t margin-sm-0t no-padd">
                    <div className="no-padd-inner">
                        <div className="prague-counter multi_item no-figure">
                            <div className="figures">
                            </div>

                            <div className="team-wrapper no-figure pt-0">
                                <div className="trans_figures enable_anima"></div>

                                <div className="team-outer" style={{height: "465px"}}>
                                    <EditableImage className={'h-100'} id={'home.intro'}
                                                   fallbackUrl={'img/zdravydom-domov-onas.jpg'}
                                                   imageRender={(url: string, image: IEditableImage) => (
                                                       <img src={url} className="prague-team-img h-100 o-cover"
                                                            alt={image.file.name}/>
                                                   )}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="column col-sm-12 col-lg-offset-1 col-lg-5 col-md-offset-0 col-md-6 col-xs-12 margin-sm-20t padd-only-xs">
                    <div className="padd-only">
                        <div className="heading left dark">
                            <h2 className="title display-linebreak position-relative w-max-content">
                                <EditableText id={'home.intro.title'} inputType={'textarea'}>
                                    Viva House přináší
                                    na trh modulové domy
                                </EditableText>
                            </h2>
                            <div className="content padding-xs-40b">
                                <EditableText wrap={true} id={'home.intro.description'} inputType={'wysiwyg'} fallback={
                                    <p>Naším cílem je nabídnout dostupné bydlení, které své majitele nezatíží vysokými
                                        vstupními a provozními náklady. Je pro vás zděný dům příliš drahý? Modulové domy
                                        představují výhodnou alternativu, která naplní potřeby každé rodiny.</p>
                                }/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row-fluid row-no-padding">
            <div className="no-padd col-sm-12">
                <div className="column-inner">
                    <div className="prague-shortcode-parent">
                        <div className="prague-shortcode-parent-img overflow-hidden">
                            <span className="overlay z-index-1"></span>
                            <EditableImage className={'h-100'} id={'home.why'} fallbackUrl={'img/wood.jpg'}
                                           imageRender={(url: string) => (
                                               <img src={url} alt="middle-banner image"
                                                    className={'o-cover w-100 h-100'}/>
                                           )}/>
                        </div>

                        <div className="prague-shortcode-content-wrapp">
                            <div className="prague-shortcode-heading light left">
                                <h2 className="parent-title">Proč právě Viva House?</h2>
                            </div>
                            <div data-unique-key="0bf3c8aa9017e52dd041b7e2c3327621" className="js-load-more"
                                 data-start-page="1" data-max-page="2" data-next-link="index.php">
                                <Row
                                    className="prague_services prague_count_col4 prague_gap_col15 no-footer-content prague-load-wrapper"
                                    data-columns="prague_count_col3" data-gap="prague_gap_col15">
                                    <div className="portfolio-item-wrapp prague_filter_class">
                                        <div className="portfolio-item">
                                            <div className="prague-services-wrapper">
                                                {VivaIcons.rating}
                                                <h3 style={{fontWeight: "normal", fontSize: "24px"}}
                                                    className="services-item-title">Spokojenost</h3>
                                                <div className="services-item-description">
                                                    <h3 style={{fontSize: "24px"}}>Zákazníka</h3>
                                                </div>

                                                <Link to="proc-viva-house#spokojenost"
                                                      className="prague-services-link a-btn-2 creative">
                                                    <span className="a-btn-line"></span>
                                                    VÍCE
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-item-wrapp prague_filter_class">
                                        <div className="portfolio-item">
                                            <div className="prague-services-wrapper">
                                                {VivaIcons.pen}
                                                <h3 style={{fontWeight: "normal", fontSize: "24px"}}
                                                    className="services-item-title">Jedinečný</h3>
                                                <div className="services-item-description">
                                                    <h3 style={{fontSize: "24px"}}>Dizajn</h3>
                                                </div>

                                                <Link to="proc-viva-house#dizajn"
                                                      className="prague-services-link a-btn-2 creative">
                                                    <span className="a-btn-line"></span>
                                                    VÍCE
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="portfolio-item-wrapp prague_filter_class p_f_f9e81a7 column_paralax">
                                        <div className="portfolio-item">
                                            <div className="prague-services-wrapper">
                                                {VivaIcons.pig}
                                                <h3 style={{fontWeight: "normal", fontSize: "24px"}}
                                                    className="services-item-title">Šetří</h3>
                                                <div className="services-item-description">
                                                    <h3 style={{fontSize: "24px"}}>Provozní náklady</h3>
                                                </div>

                                                <Link to="proc-viva-house#setrenie"
                                                      className="prague-services-link a-btn-2 creative" target="_self">
                                                    <span className="a-btn-line"></span>
                                                    VÍCE
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-item-wrapp prague_filter_class p_f_f9e81a7">
                                        <div className="portfolio-item">
                                            <div className="prague-services-wrapper">
                                                {VivaIcons.like}
                                                <h3 style={{fontWeight: "normal", fontSize: "24px"}}
                                                    className="services-item-title">Vysoká kvalita</h3>
                                                <div className="services-item-description">
                                                    <h3 style={{fontSize: "24px"}}>A rychlost stavby</h3>
                                                </div>

                                                <Link to="/proc-viva-house#kvalita"
                                                      className="prague-services-link a-btn-2 creative" target="_self">
                                                    <span className="a-btn-line"></span>
                                                    VÍCE
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container no-padd">
            <div className="row-fluid margin-lg-55t margin-sm-20t">
                <div className="col-sm-12 no-padd margin-lg-20b margin-xs-40t">
                    <div>
                        <div className="heading left dark">
                            <h2 className="d-flex justify-content-center title">Modulové domy</h2>
                            <div className={'d-flex justify-content-center text-center m-auto'}
                               style={{width: "80%"}}>
                                <EditableText wrap={true} id={'home.modular-houses.description'} inputType={'wysiwyg'} fallback={
                                    <p>Modulové
                                        dřevostavby jsou moderním, cenově dostupným řešením bydlení. Všechny domy jsou
                                        vyhotoveny z kvalitních materiálů v nízkoenergetickém standardu. Poskytují zdravé
                                        prostředí a dokonalý komfort, který uspokojí i náročného klienta</p>
                                }/>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container no-padd">
            <div className="row-fluid margin-lg-5t margin-lg-140b margin-sm-70b no-padd">
                <div className="column no-padd column_container col-sm-12 margin-lg-5t margin-lg-140b margin-sm-70b">
                    <div className="column-inner">
                        <div className="wrapper">
                            <div data-unique-key="d30797fabd788147235e48099dfaacc0" className="js-load-more"
                                 data-start-page="1" data-max-page="4">
                                <Row gutter={[32, 20]} justify={"space-around"} className={'w-100 m-0'}>
                                    <SortableContainer onSort={onDragEnd}
                                                       ids={onlyHomePageHouses.map(h => h.id) || []}
                                                       mode={'grid'}
                                                       renderOverlay={id => {
                                                           const draggedHouse = onlyHomePageHouses.find(h => h.id === id)
                                                           return draggedHouse ?
                                                               <SortableItem key={draggedHouse.id}
                                                                             render={(styles, ref, isDragging, props) => renderHouse(ref, styles, isDragging, draggedHouse, props)}
                                                                             id={draggedHouse.id}/> : <></>
                                                       }}>
                                        {onlyHomePageHouses.map(house => (
                                            <SortableItem key={house.id}
                                                          render={(styles, ref, isDragging, props) => renderHouse(ref, styles, isDragging, house, props)}
                                                          id={house.id}/>
                                        ))}
                                    </SortableContainer>
                                </Row>
                                <div className="row m-0">
                                    <br/>
                                    <div className="col-sm-12 text-center">
                                        <Link to={'modulovy-domy'}>
                                            <button className="a-btn-2 creative viva-button">
                                                <span className="a-btn-line load"></span>
                                                Zobrazit všechny
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


export default connector(Home);