import React, {FC} from "react";
import moment from "moment";
import EditableText from "../page/component/EditableText";
import EditableImage from "../page/component/EditableImage";
import {IStore} from "../../../redux/IStore";
import {connect} from "react-redux";
import {IEditableText} from "../../../api/entity/home/IEditableText";

interface IProps {
    editableTexts: IEditableText[]
}

const Footer: FC<IProps> = ({editableTexts}) => {

    const phone = editableTexts.find(m => m.id === 'contact.phone')
    const email = editableTexts.find(m => m.id === 'contact.email')

    return <EditableImage id={'footer.cover.image'} fallbackUrl={'/img/lyon_footer.jpg'}
                          imageRender={(url: string) => (<footer className="prague-footer default" style={{
                              backgroundImage: 'url("' +  url + '")',
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat'
                          }}>
                              <div className="footer-content-outer">
                                  <div className="footer-top-content">
                                      <div className="prague-footer-main-block">
                                          <div className="prague-logo">
                                              <a href="">
                                                  <img src="/img/logo.png" style={{width: "214px"}}
                                                       className="attachment-full size-full"
                                                       alt="logo"/> </a>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="container no-padd margin-lg-75t margin-sm-30t margin-md-0t">
                                      <div className="row-fluid ">
                                          <div
                                              className="  margin-md-90t margin-sm-20t col-sm-12 col-lg-offset-0 col-lg-6 col-md-offset-0 col-md-6 col-sm-offset-0 col-xs-12 padd-only-xs">
                                              <div className="heading  left dark p-0">

                                                  <h2 className="title font-white position-relative">
                                                      <EditableText id={'footer.title'} editButtonPosition={{right: 20}}
                                                                    inputType={'textarea'}
                                                                    fallback={'Navštivte náš vzorový dům'}/>
                                                  </h2>


                                                  <div className="content font-white">
                                                      <EditableText wrap={true} id={'footer.description'}
                                                                    editButtonPosition={{right: 20}}
                                                                    inputType={'wysiwyg'} fallback={
                                                          <>
                                                              <p>Přijďte si prohlédnout náš vzorový dům nedaleko
                                                                  Prahy a
                                                                  přesvědčte se na vlastní oči. Návštěva vzorového domu
                                                                  Vás k ničemu
                                                                  nezavazuje.</p>
                                                              <p>Prohlídky jsou možné po telefonické domluvě.</p>
                                                          </>
                                                      }/>

                                                  </div>
                                              </div>
                                              <div className="row-fluid vc_custom_1488483852815">
                                                  <div className="col-sm-4 no-padd no-padd">
                                                      <div className="column-inner">
                                                          <div className="adddress-block  ">

                                                              <div className="address-block-outer">
                                                                  <span className="separator"></span>

                                                                  <h4 className="address-title font-white">Telefonní
                                                                      číslo</h4>

                                                                  <p>
                                                                      <a className="font-white position-relative"
                                                                         href={'tel:+420' + (phone?.versions[0]?.text || '737 255 647').replaceAll(' ', '')}>
                                                                          <EditableText id={'contact.phone'}
                                                                                        fallback={'737 255 647'}/></a>
                                                                  </p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="col-sm-4 no-padd no-padd">
                                                      <div className="adddress-block">

                                                          <div className="address-block-outer">
                                                              <span className="separator"></span>

                                                              <h4 className="address-title font-white">Email /
                                                                  Jméno</h4>

                                                              <p className={'font-white'}>
                                                                  <a className="font-white position-relative"
                                                                     href={"mailto:" + email?.versions[0]?.text || 'info@vivahouse.eu'}>
                                                                      <EditableText inputType={'email'} id={'contact.email'} fallback={'info@vivahouse.eu'}/></a><br/>
                                                                  <a className={'position-relative'}><EditableText id={'contact.name'} fallback={'Ing. Monika Vavreková'}/></a>
                                                              </p>
                                                          </div>
                                                      </div>
                                                  </div>

                                              </div>
                                          </div>
                                          <div
                                              className="col-sm-12 col-md-6 col-xs-12  margin-sm-40t margin-md-190t no-padd">
                                              <iframe style={{width: "100%", height: "350px", marginBottom: "0px"}}
                                                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d320.37787997407264!2d14.612079295727119!3d50.029664102194765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b8c4be4f93185%3A0xfc4d4f394352966b!2zUMWZw6F0ZWxzdHbDrSA4MzEvMTE0LCAxMDQgMDAgUHJhaGEgMjItVWjFmcOtbsSbdmVzLCBDemVjaGlh!5e0!3m2!1sen!2ssk!4v1724184410231!5m2!1sen!2ssk"
                                                      loading="lazy"
                                                      referrerPolicy="no-referrer-when-downgrade"></iframe>
                                              {/*<iframe style={{width: "100%", height: "350px", marginBottom: "0px"}}*/}
                                              {/*        loading="lazy"*/}
                                              {/*        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=trnov%C3%A1%20105%20praha+(Vzorov%C3%BD%20d%C5%AFm)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"*/}
                                              {/*        title="Praha" aria-label="Praha"></iframe>*/}
                                          </div>
                                      </div>
                                  </div>
                                  <div className="footer-bottom-content">
                                      <div className="footer-copyright">
                                          <p>© {moment().format('Y')} Vivahouse.eu s.r.o. Všechna práva vyhrazena.</p>
                                      </div>
                                      {/*
                  <!-- End footer copyright -->
                <!--
                <div className="prague-social-nav">


                    <ul className="social-content">
                        <li>
                            <a target="_blank">
                                <i aria-hidden="true" className="fa fa-instagram"></i>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.facebook.com/zdravydom.com.s.r.o">
                                <i aria-hidden="true" className="fa fa-facebook"></i>
                            </a>
                        </li>
                    </ul>

                </div>
                            -->
                */}
                                  </div>
                              </div>
                          </footer>)}/>
}


const mapStateToProps = ({setup}: IStore) => {
    const {editableTexts} = setup
    return {editableTexts}
}



export default connect(mapStateToProps)(Footer);