// src/ScrollToTop.jsx
import {FC, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTop: FC = ({children}) => {
    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
        if (location.hash === '') {

        }
        else {
            // setTimeout(() => {
            //     const id = location.hash.replace('#', '');
            //     const element = document.getElementById(id);
            //     if (element) {
            //         element.scrollIntoView();
            //     }
            // }, 0);
        }
    }, [location])

    return <>{children}</>
};

export default ScrollToTop