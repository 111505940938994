import qs from 'qs'
import {Key} from "react";
import fetch from "./FetchInterceptor";


export type IEntity = {
    id: number
    [name: string]: any
} | any


export type ApiFilterType =
    'or'
    | 'and'
    | 'in'
    | 'notIn'
    | 'contains'
    | 'equal'
    | 'notequal'
    | 'lesser'
    | 'lesserOrEqual'
    | 'greater'
    | 'greaterOrEqual'
    | 'isNull'
    | 'isNotNull'
    | 'like'
    | 'likeBeginning'

export interface IApiFilter {
    field: string
    type: ApiFilterType
    value: any
    children?: IApiFilters
}

export interface IApiFilters {
    [name: string | number]: IApiFilter
}

export interface IApiOrderItem {
    field: string
    direction: 'ASC' | 'DESC'
}

export interface IApiOrder {
    [name: string | number]: IApiOrderItem
}

export interface IApiParams {
    filters?: IApiFilters
    order?: IApiOrder
    depth?: number
}

export interface IApiService<T = any> {
    list: (params?: IApiParams) => Promise<T[]>
    get: (id: Key) => Promise<T>
    create: (data: { [key in keyof T]?: T[key] }) => Promise<T>
    update: (id: Key, data: { [key in keyof T]?: T[key] }) => Promise<T>
    delete: (id: Key) => Promise<void>
    upload?: (data: FormData, onUploadProgress?: (progressEvent: ProgressEvent) => void) => Promise<T[]>

    [key: string]: any
}

export interface IBaseApiService {
    list: (collection: string, params?: IApiParams) => Promise<IEntity[]>
    get: (collection: string, id: Key) => Promise<IEntity>
    create: (collection: string, data: any, fileUpload?: boolean) => Promise<IEntity>
    update: (collection: string, id: Key, data: any) => Promise<IEntity>
    delete: (collection: string, id: Key) => Promise<void>
    upload: (collection: string, data: FormData, onUploadProgress?: (progressEvent: ProgressEvent) => void) => Promise<IEntity>
}

export const buildUrl = (collection: string) => {
    return `${window.location.protocol}//${window.location.hostname === 'localhost' ? 'vivahouse' : window.location.hostname + ''}/api/` + collection
}


const ApiService: IBaseApiService = {
    list: (collection, params) => {
        return fetch.get(buildUrl(collection), {
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params, {
                    arrayFormat: "brackets",
                    encode: false
                });
            }
        }).then(response => response.data)
    },
    get: (collection, id) => {
        return fetch.get(buildUrl(collection) + '/' + id, {
            method: 'GET'
        }).then(response => response.data)
    },
    create: (collection, data: any) => {
        return fetch.post(buildUrl(collection), data).then(response => response.data)
    },
    update: (collection, id, data: any) => {
        return fetch.post(buildUrl(collection), {...data, id: id}).then(response => response.data)
    },
    delete: (collection, id) => {
        return fetch.delete(buildUrl(collection) + '/' + id, {}).then(response => response.data)
    },
    upload: (collection, data, onUploadProgress) => {
        return fetch.post(buildUrl(collection), data, {
            headers: {"Content-Transfer-Encoding": "multipart/form-data"},
            onUploadProgress: (progressEvent) => {
                onUploadProgress?.(progressEvent)
            }
        }).then(response => response.data)
    }
}

export default ApiService