import React, {useEffect, useState} from "react"
import './loading.sass'
import {useLocation} from "react-router-dom";

interface IProps {
    loading: boolean
}

const Loading: React.FC<IProps> = ({loading, children}) => {

    const location = useLocation()
    const [pageChange, setPageChange] = useState(false);


    useEffect(() => {
        setPageChange(true)
        setTimeout(() => {
            setPageChange(false)
        }, 300)
    }, [location])

    return (
        <div className={'loading'}>
            <div className={"prague-loader" + (loading || pageChange ? '' : ' is-loaded')} style={{width: '100vw', zIndex: 1000}}>
                <div className="prague-loader-wrapper" style={{width: '100vw'}}>
                    <div className="prague-loader-bar">Viva House</div>
                </div>
            </div>
            {children}
        </div>
    )
}

export default Loading
