import React, {useState} from "react";
import {Alert, Button, Card, Form, Image, Input, notification, Row} from "antd";
import {LoginOutlined} from "@ant-design/icons";
import TokenService from "../../api/service/security/TokenService";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {login} from "../../redux/actions/Setup";
import {IStore} from "../../redux/IStore";


interface IProps {
    login: (token: string) => void
}

const LoginForm: React.FC<IProps> = ({login}) => {

    const navigate = useNavigate();
    const [logging, setLogging] = useState<boolean>(false);
    const [failure, setFailure] = useState<boolean>(false);


    const submit = (credentials: { email: string, password: string }) => {
        setLogging(true)
        TokenService.load(credentials.email, credentials.password).then((result) => {
            if (!result) {
                return Promise.reject()
            }
            login(result)
            navigate('/')
            notification.success({message: 'Úspěšně přihlášeni'})
        })
            .catch(() => setFailure(true))
            .finally(() => setLogging(false))
    }

    return <Row justify={'center'} className={'w-100'}
                style={{backgroundImage: "url(/img/wood.jpg)", height: '100vh', backgroundSize: "cover"}}>
        <div>
            <Card style={{backgroundColor: "black", marginTop: 65, border: 0, padding: 20}}
                  cover={<Image src="/img/logo.png" preview={false}/>}>
                <Form onFinish={submit}>
                    <Form.Item label={<div className={'font-white'}>Email</div>} name={'email'}
                               rules={[{required: true, message: 'Políčko je vyžadováno'}]}>
                        <Input type={'email'}/>
                    </Form.Item>
                    <Form.Item className={'font-white'} label={<div className={'font-white'}>Heslo</div>}
                               name={'password'} rules={[{required: true, message: 'Políčko je vyžadováno'}]}>
                        <Input.Password/>
                    </Form.Item>
                    {failure && (
                        <Alert className={'mb-2'} showIcon={true} type={'error'} message={'Neplatné přihlašovací údaje'}></Alert>
                    )}
                    <Row justify={"end"}>
                        <Button size={'large'} loading={logging} icon={<LoginOutlined/>} htmlType={'submit'}>Login</Button>
                    </Row>
                </Form>
            </Card>
        </div>

    </Row>
}

const mapStateToProps = ({setup}: IStore) => {
    const {loading} = setup
    return {loading}
};

const mapDispatchToProps = {
    login: (token: string) => login(token)
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(LoginForm));