import {useSortable} from "@dnd-kit/sortable";
import React, {CSSProperties} from "react";
import {CSS} from '@dnd-kit/utilities';


interface DraggableItemProps {
    render: (styles: CSSProperties, ref: (node: HTMLElement | null) => void, isDragging: boolean, props: any) => React.ReactElement<any, string | React.JSXElementConstructor<any>>
    id: string
}

const SortableItem = ({render, id}: DraggableItemProps) => {
    const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id});

    const style: CSSProperties = {transform: CSS.Transform.toString(transform), transition};

    return render(style, setNodeRef, isDragging, {...attributes, ...listeners});
}

export default SortableItem
