import ApiService, {buildUrl, IApiService} from "./ApiService";
import {IFile} from "../entity/file/IFile";
import {Key} from "react";

const COLLECTION = 'files'

interface IService extends IApiService<IFile>{
    preview: (id: Key) => string
}

const FileService: IService = {
    list: (params) => {
        return ApiService.list(COLLECTION, params)
    },
    get: (id) => {
        return ApiService.get(COLLECTION, id)
    },
    create: (data) => {
        return ApiService.create(COLLECTION, data)
    },
    update: (id, data) => {
        return ApiService.update(COLLECTION, id, data)
    },
    delete: (id) => {
        return ApiService.delete(COLLECTION, id)
    },
    upload: (data, onUploadProgress) => {
        return ApiService.upload(COLLECTION, data, onUploadProgress)
    },
    preview: (id) => {
        return buildUrl(COLLECTION + '/' + id + '/preview')
    }
}

export default FileService