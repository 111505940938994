import ApiService, {IApiService} from "../ApiService";
import {IEditableText} from "../../entity/home/IEditableText";

const COLLECTION = 'editable-text'

const EditableTextService: IApiService<IEditableText> = {
    list: (params) => {
        return ApiService.list(COLLECTION, params)
    },
    get: (id) => {
        return ApiService.get(COLLECTION, id)
    },
    create: (data) => {
        return ApiService.create(COLLECTION, data)
    },
    update: (id, data) => {
        return ApiService.update(COLLECTION, id, data)
    },
    delete: (id) => {
        return ApiService.delete(COLLECTION, id)
    }
}

export default EditableTextService