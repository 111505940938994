import {
  SETUP_END_LOADING,
  SETUP_LOAD,
  SETUP_LOGIN,
  SETUP_LOGOUT,
  SETUP_RELOAD,
  SETUP_SAVE,
  SETUP_START_LOADING,
  SETUP_UPDATE
} from '../constants/Setup';
import {ISetup} from "../../api/service/SetupService";
import {ISetupStateUpdate} from "../reducers/Setup";

export const startLoading = () => {
  return {
    type: SETUP_START_LOADING,
    loading: true
  }
};
export const endLoading = () => {
  return {
    type: SETUP_END_LOADING,
    loading: false
  }
};
export const load = () => {
  return {
    type: SETUP_LOAD
  }
};
export const logout = () => {
  return {
    type: SETUP_LOGOUT
  }
};
export const login = (token: string) => {
  return {
    type: SETUP_LOGIN,
    token
  }
};
export const reLoad = () => {
  return {
    type: SETUP_RELOAD
  }
};
export const save = (response: ISetup) => {
  return {
    type: SETUP_SAVE,
    ...response
  }
};

export const update = (response: ISetupStateUpdate) => {
  return {
    type: SETUP_UPDATE,
    ...response
  }
};

const actions =  {
  load,
  reLoad,
  save
}

export default actions