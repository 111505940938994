import React, {FC, useEffect, useState} from "react";
import {IModularHouse} from "../../../api/entity/home/IModularHouse";
import {IStore} from "../../../redux/IStore";
import {connect} from "react-redux";
import {update} from "../../../redux/actions/Setup";
import ModularHouseService from "../../../api/service/home/ModularHouseService";
import {ISetupState, ISetupStateUpdate} from "../../../redux/reducers/Setup";
import RecordsOrderService, {
    HOME_PAGE_ORDER,
    NEWS_PAGE_ORDER,
    SIP_HOUSE_HOME_ORDER
} from "../../../api/service/home/RecordsOrderService";
import {IRecordsOrder} from "../../../api/entity/home/IRecordsOrder";
import {IUser, ROLES} from "../../../api/entity/security/IUser";
import EditableText from "./component/EditableText";
import EditableImage from "./component/EditableImage";
import {Alert, Button, Col, Popconfirm, Row} from "antd";
import SortableContainer from "../../../shared/structure/sortable/SortableContainer";
import SortableItem from "../../../shared/structure/sortable/SortableItem";
import {Link} from "react-router-dom";
import {ISipHouse} from "../../../api/entity/home/ISipHouse";
import SipHouseService from "../../../api/service/home/SipHouseService";
import {DeleteOutlined, DragOutlined, EditOutlined, EyeInvisibleOutlined} from "@ant-design/icons";
import FileService from "../../../api/service/FileService";


interface IProps {
    sipHouses: ISipHouse[],
    recordsOrder: IRecordsOrder[]
    update: (changes: ISetupStateUpdate) => void,
    user?: IUser
}

const Sip: FC<IProps> = ({sipHouses, update, recordsOrder, user}) => {

    useEffect(() => {
        let result: ISipHouse[] = []

        const home = recordsOrder.find(m => m.id === SIP_HOUSE_HOME_ORDER)
        if (home) {
            sipHouses.forEach(function (a) {
                if (home.order.indexOf(a.id) > -1) {
                    result[home.order.indexOf(a.id)] = a
                }
            });
            sipHouses.forEach(function (a) {
                if (home.order.indexOf(a.id) === -1) {
                    result.push(a)
                }
            });
            update({modularHouses: result})
        }
    }, [recordsOrder]);


    const onDragEnd = (ids: string[]) => {
        let result: ISipHouse[] = []

        sipHouses.forEach(function (a) {
            if (ids.indexOf(a.id) > -1) {
                result[ids.indexOf(a.id)] = a
            }
        });
        sipHouses.forEach(function (a) {
            if (ids.indexOf(a.id) === -1) {
                result.push(a)
            }
        });
        RecordsOrderService.create({id: SIP_HOUSE_HOME_ORDER, order: result.filter(h => h.onHomepage).map(h => h.id)}).then(order => {
            let temp = [...recordsOrder]
            const index = temp.findIndex(o => o.id === order.id)
            temp[index] = order
            update({recordsOrder: temp})
        })

        update({modularHouses: result})
    }


    function renderHouse(ref: (node: (HTMLElement | null)) => void, styles: React.CSSProperties, isDragging: boolean, house: ISipHouse, props: any) {
        return <Col xs={24} sm={12} lg={8} ref={ref}
                    style={{...styles, maxWidth: '380px'}}
                    className={isDragging ? 'is-dragging p-0' : ''}>
            <div className="project-grid-wrapper zoom-in">
                <Link className="project-grid-item-img-link"
                      to={"/sip/katalog/" + encodeURI(house.name.replaceAll(' ', '_'))}>
                    <div className="project-grid-item-img">
                        <img src={FileService.preview(house.images[0]?.uid || '')}
                             className="s-img-switch wp-post-image"
                             alt={house.name}/>
                    </div>
                </Link>

                <div className="project-grid-item-content">
                    <Row justify={"space-between"} align={'middle'}>
                        <div></div>
                        <Col>
                            <h4 className="project-grid-item-title text-capitalize">
                                <Link className={'text-uppercase'}
                                      to={"/sip/katalog/" + encodeURI(house.name.replaceAll(' ', '_'))}>{house.name}</Link>
                            </h4>
                            <div
                                className="project-grid-item-category">{house.area} m2
                            </div>
                        </Col>
                        <Col className={'flex flex-column'}>
                            {user?.roles.includes(ROLES.EDITOR) && (
                                <Button shape={'circle'}  {...props} style={{cursor: 'move'}}
                                        icon={<DragOutlined/>} className={'mb-1'}/>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        </Col>;
    }

    const onlyHomePageHouses = sipHouses.filter(h => h.onHomepage)


    return <div>
        <div className={'row m-0'}>
            <div className="col-xs-12 no-padd">
                <EditableImage className={'h-100'} id={'sip.cover'} editButtonPosition={{top: 110, right: 20}}
                               fallbackUrl={'/img/sip/img.png'}
                               imageRender={(url: string) => (
                                   <div className="container-fluid top-banner no-padd big fullheight light" style={{
                                       background: 'url(' + url + ')',
                                       backgroundPosition: 'center',
                                       backgroundSize: 'cover'
                                   }}>
                                       <span className="overlay"></span>
                                       <div className="contentmdum">
                                           <div className="prague-svg-animation-text"></div>
                                           <div className="subtitle position-relative d-inline-block"
                                                style={{fontSize: 30}}>
                                               <EditableText
                                                   id={'sip.cover.small.title'} fallback={'DOMY SIP'}></EditableText>
                                           </div>
                                           <h1 className="title display-linebreak position-relative"
                                               style={{width: 'max-content'}}>
                                               <EditableText id={'sip.cover.big.title'}
                                                             inputType={'textarea'} fallback={'Inovativní stavební'}>
                                                   systém</EditableText>
                                           </h1>
                                           {/*
                                                <!-- <div class="description">
                          <p>Zajišťujeme komfortní řízení stavby</p>
                          <p>bez zbytečného přerušování pro maximalizaci efektivity.</p>
                        </div> -->
                        */}
                                       </div>
                                       <div className="top-banner-cursor"></div>
                                   </div>
                               )}/>
            </div>
        </div>
        <div className="container padd-only-xs margin-lg-55t">
            <div className="row">
                <div className="col-xs-12 padd-only-xs">
                    <div className="container no-padd">
                        <Row className="row-fluid margin-lg-35t" gutter={30}>
                            <Col sm={12} xs={24}>
                                <div className="padd-only">
                                    <div className="left dark">
                                        <h2 className="title">
                                            SIP Panely
                                        </h2>
                                        <div className="content padding-xs-40b position-relative">
                                            <EditableText id={'sip.description'} inputType={'wysiwyg'} fallback={<div>
                                                <p>Specializujeme se na stavební systém SIP (Structured Insulated
                                                    Panel), který se celosvětově používá již 40 let (v Kanadě 80% a v
                                                    USA 65% všech rodinných domů je postaveno touto jedinečnou
                                                    technologií).</p>
                                                <p>
                                                    Domy ze SIP panelů jsou energeticky úsporné a vyžadují minimální
                                                    náklady na jejich provoz a údržbu..
                                                </p>
                                            </div>}>
                                            </EditableText>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} xs={24}>
                                <div className="no-padd-inner">
                                    <div className="ml-4">
                                        <div className={'sip-image position-relative'}>
                                            <img src="/img/sip/about.png"
                                                 className="prague-team-img border-radius-10 o-cover "
                                                 alt={'about'}/>
                                            <div className={'tag'}>
                                                Složení SIP panelů
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
        <div className={'margin-lg-70t'}>
            <div className="col-xs-12 no-padd">
                <EditableImage className={'h-100'} id={'sip.cover'} editButtonPosition={{top: 110, right: 20}}
                               fallbackUrl={'/img/sip/advantages.png'}
                               imageRender={(url: string) => (
                                   <img src={url} className="container-fluid no-padd light" alt={'info'}>
                                   </img>
                               )}/>
            </div>
        </div>
        <div className="container padd-only-xs margin-xs-20t margin-md-0t">
            <div className="row">
                <div className="col-xs-12 padd-only-xs">
                    <div className="container no-padd">
                        <div className="row-fluid margin-lg-70t">
                            <h2 className="title text-center margin-lg-45b">
                                Naše Projekty
                            </h2>
                            <div className="column-inner">
                                <div className="wrapper">
                                    <div data-unique-key="d30797fabd788147235e48099dfaacc0" className="js-load-more"
                                         data-start-page="1" data-max-page="4">
                                        <Row gutter={[32, 20]} justify={"space-around"} className={'w-100 m-0'}>
                                            <SortableContainer onSort={onDragEnd}
                                                               ids={onlyHomePageHouses.map(h => h.id) || []}
                                                               mode={'grid'}
                                                               renderOverlay={id => {
                                                                   const draggedHouse = onlyHomePageHouses.find(h => h.id === id)
                                                                   return draggedHouse ?
                                                                       <SortableItem key={draggedHouse.id}
                                                                                     render={(styles, ref, isDragging, props) => renderHouse(ref, styles, isDragging, draggedHouse, props)}
                                                                                     id={draggedHouse.id}/> : <></>
                                                               }}>
                                                {onlyHomePageHouses.map(house => (
                                                    <SortableItem key={house.id}
                                                                  render={(styles, ref, isDragging, props) => renderHouse(ref, styles, isDragging, house, props)}
                                                                  id={house.id}/>
                                                ))}
                                            </SortableContainer>
                                        </Row>
                                        <div className="row m-0">
                                            <br/>
                                            <div className="col-sm-12 text-center">
                                                <Link to={'/sip/katalog'}>
                                                    <button className="a-btn-2 creative viva-button">
                                                        <span className="a-btn-line load"></span>
                                                        Zobrazit všechny
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container padd-only-xs margin-xs-20t margin-md-0t margin-lg-90b">
            <div className="row">
                <div className="col-xs-12 padd-only-xs">
                    <div className="container no-padd">
                        <div className="row-fluid margin-lg-70t">
                            <h2 className="title text-center margin-lg-45b">
                                Naše Služby
                            </h2>
                            <Row gutter={40}>
                                <Col sm={24} md={8} flex={'1'}>
                                    <div id="spokojenost" className="portfolio-item-wrapp prague_filter_class h-100">
                                        <div className="portfolio-item h-100">
                                            <div className="prague-services-wrapper h-100">
                                                <img style={{height: 50}} className={'mb-3'}
                                                     src={'/img/sip/icon/icon-1.png'} alt={'icon'}/>
                                                <h3 className="services-item-title">Konstrukce domu</h3>
                                                <div className="services-item-description">
                                                    <EditableText wrap={true}
                                                                  id={'sip.service.reconstruction.description'}
                                                                  inputType={'wysiwyg'} fallback={
                                                        <p>Konstrukce domu se skládá z nosných stěn ze SIP panelů.
                                                            Vnitřní příčky konstrukce s protihlukovou izolací
                                                            Záruka na konstrukční systém 30 let!</p>
                                                    }/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={24} md={8} flex={'1'}>
                                    <div id="spokojenost" className="portfolio-item-wrapp prague_filter_class h-100">
                                        <div className="portfolio-item h-100">
                                            <div className="prague-services-wrapper h-100">
                                                <img style={{height: 50}} className={'mb-3'} src={'/img/sip/icon/icon-2.png'} alt={'icon'}/>
                                                <h3 className="services-item-title">Fasáda</h3>
                                                <div className="services-item-description">
                                                    <EditableText wrap={true} id={'sip.service.facade.description'}
                                                                  inputType={'wysiwyg'} fallback={
                                                        <p>Obvodový zateplovací systém
                                                            Omítka vnější silikonová + sokl - silikonová omítka
                                                            jednobarevná do výšky 40 cm</p>
                                                    }/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={24} md={8} flex={'1'}>
                                    <div id="spokojenost" className="portfolio-item-wrapp prague_filter_class h-100">
                                        <div className="portfolio-item h-100">
                                            <div className="prague-services-wrapper">
                                                <img style={{height: 50}} className={'mb-3'} src={'/img/sip/icon/icon-3.png'} alt={'icon'}/>
                                                <h3 className="services-item-title">INTERIÉR</h3>
                                                <div className="services-item-description">
                                                    <EditableText wrap={true} id={'sip.service.interior.description'}
                                                                  inputType={'wysiwyg'} fallback={
                                                        <p>Vnitřní kanalizace + vodovod
                                                            Hrubá instalace ELI<br/>
                                                            Izolace podlahy<br/>
                                                            Potěry<br/>
                                                            Izolace stropu<br/>
                                                            Podhledy<br/>
                                                            Topení</p>
                                                    }/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


const mapStateToProps = ({setup}: IStore) => {
    const {sipHouses, recordsOrder, user} = setup
    return {sipHouses, recordsOrder: recordsOrder, user}
};

const mapDispatchToProps = {
    update: (changes: { [key in keyof ISetupState]?: ISetupState[key] }) => update(changes),
}


export default connect(mapStateToProps, mapDispatchToProps)(Sip);