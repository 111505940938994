import {buildUrl} from "./ApiService";
import fetch from "./FetchInterceptor";


export interface ISetup {

}

const COLLECTION = 'setup'

const SetupService = {
    list: (): Promise<ISetup> => {
        return fetch.get(buildUrl(COLLECTION)).then(response =>  {
            return response.data
        })
    }
}

export default SetupService