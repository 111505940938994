import {
    SETUP_END_LOADING,
    SETUP_LOAD,
    SETUP_RELOAD,
    SETUP_SAVE,
    SETUP_START_LOADING,
    SETUP_UPDATE
} from '../constants/Setup';

import {IRecordsOrder} from "../../api/entity/home/IRecordsOrder";
import {TOKEN_KEY} from "../../api/service/FetchInterceptor";
import {IRealization} from "../../api/entity/home/IRealization";
import {IModularHouse} from "../../api/entity/home/IModularHouse";
import {IUser} from "../../api/entity/security/IUser";
import {IEditableImage} from "../../api/entity/home/IEditableImage";
import {IEditableText} from "../../api/entity/home/IEditableText";
import {ISipHouse} from "../../api/entity/home/ISipHouse";
import {ISipRealization} from "../../api/entity/home/ISipRealization";

export type ISetupState = {
    loading: boolean,
    modularHouses: IModularHouse[]
    realizations: IRealization[]
    sipHouses: ISipHouse[]
    sipRealizations: ISipRealization[]
    recordsOrder: IRecordsOrder[]
    editableImages: IEditableImage[]
    editableTexts: IEditableText[]
    token?: string
    user?: IUser
}

export type ISetupStateUpdate = {[key in keyof ISetupState]?: ISetupState[key]}

type IAction = {
    type: string,
    [x: string]: any
}

const initState: ISetupState = {
    loading: true,
    modularHouses: [],
    realizations: [],
    recordsOrder: [],
    editableImages: [],
    editableTexts: [],
    sipHouses: [],
    sipRealizations: []
}

const setup = (state = initState, action: IAction) => {
    state.token = localStorage.getItem(TOKEN_KEY) || undefined

    switch (action.type) {
        case SETUP_START_LOADING:
        case SETUP_END_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        case SETUP_LOAD: {
            return {
                ...state,
            }
        }
        case SETUP_RELOAD: {
            return {
                ...state
            }
        }
        case SETUP_SAVE: {
            return {
                ...state,
                loading: false,
                modularHouses: action.modularHouses || [],
                realizations: action.realizations || [],
                recordsOrder: action.recordsOrder || [],
                editableImages: action.editableImages || [],
                editableTexts: action.editableTexts || [],
                sipHouses: action.sipHouses || [],
                sipRealizations: action.sipRealizations || [],
                user: action.user
            }
        }
        case SETUP_UPDATE: {
            return {
                ...state,
                ...action
            }
        }
        default:
            return state;
    }
}

export default setup