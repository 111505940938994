import {Route, Routes} from "react-router-dom";
import React from "react";
import LoginForm from "./LoginForm";

const LoginRouter = () => {

    return <Routes>
        <Route path="/" element={<LoginForm/>}/>
    </Routes>
}

export default LoginRouter