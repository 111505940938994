import VivaIcons from "../../../shared/VivaIcons";
import EditableImage from "./component/EditableImage";
import React from "react";
import EditableText from "./component/EditableText";

const WhyViva = () => {
    return <div>
        <div className="row-fluid row-no-padding">
            <div className="no-padd col-sm-12">
                <div className="column-inner">
                    <div className="prague-shortcode-parent">
                        <EditableImage className={'h-100'} id={'why-viva.cover'} editButtonPosition={{top: 50, right: 20}}
                                       fallbackUrl={'img/male.jpg'}
                                       imageRender={(url: string) => (
                                           <div className="prague-shortcode-parent-img" style={{
                                               background: 'url(' + url + ')',
                                               backgroundPosition: 'center',
                                               backgroundSize: 'cover'
                                           }}>
                                               <span className="overlay"></span>
                                           </div>
                                       )}/>

                        <div className="prague-shortcode-content-wrapp">
                            <div className="prague-shortcode-heading light left">
                                <h2 className="parent-title d-flex justify-content-center">Proč Viva House</h2>
                            </div>

                            <div data-unique-key="0bf3c8aa9017e52dd041b7e2c3327621" className="js-load-more"
                                 data-start-page="1" data-max-page="2">
                                <div
                                    className="d-flex row prague_services prague_count_col3 prague_gap_col15 no-footer-content prague-load-wrapper"
                                    data-columns="prague_count_col3" data-gap="prague_gap_col15">
                                    <div id="spokojenost" className="portfolio-item-wrapp prague_filter_class">
                                        <div className="portfolio-item">
                                            <div style={{height: "458px"}} className="prague-services-wrapper">
                                                {VivaIcons.rating}
                                                <h3 className="services-item-title">Spokojenost zákazníka</h3>
                                                <div className="services-item-description">
                                                    <EditableText wrap={true} id={'why-viva.happy.description'} inputType={'wysiwyg'} fallback={
                                                        <p>Ve středu našeho zájmu je vaše spokojenost. Získáte korektní
                                                            obchodní vztahy, individuální přístup a kvalifikované
                                                            poradenství. Každému klientovi přinášíme jen to nejlepší.
                                                            Dovolte nám doprovázet vás na cestě za vaším novým
                                                            bydlením.</p>
                                                    }/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="dizajn"
                                         className="portfolio-item-wrapp prague_filter_class column_paralax">
                                        <div className="portfolio-item">
                                            <div style={{height: "458px"}} className="prague-services-wrapper">
                                                {VivaIcons.pen}
                                                <h3 className="services-item-title">Jedinečný dizajn</h3>
                                                <div className="services-item-description">
                                                    <EditableText wrap={true} id={'why-viva.design.description'} inputType={'wysiwyg'} fallback={
                                                        <p>Každý modulární dům je sestaven z jednotlivých bloků na základě
                                                            výběru klienta. Můžete si tak navrhnout celek, který bude přesně
                                                            vyhovovat vašim požadavkům. Množství variací nabízí jedinečnost
                                                            pro každou stavbu.</p>
                                                    }/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="setrenie" className="portfolio-item-wrapp prague_filter_class">
                                        <div className="portfolio-item">
                                            <div style={{height: "458px"}} className="prague-services-wrapper">
                                                {VivaIcons.pig}
                                                <h3 className="services-item-title">Šetří provozní náklady</h3>
                                                <div className="services-item-description">
                                                    <EditableText wrap={true} id={'why-viva.savings.description'} inputType={'wysiwyg'} fallback={
                                                        <p>Každý modulární dům z naší nabídky splňuje náročné požadavky na
                                                            nízkoenergetické stavby. Nezatíží váš rozpočet vysokými výdaji
                                                            na vytápění ani údržbu.</p>
                                                    }/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="kvalita" className="portfolio-item-wrapp prague_filter_class">
                                        <div className="portfolio-item">
                                            <div style={{height: "458px"}} className="prague-services-wrapper">
                                                {VivaIcons.like}
                                                <h3 className="services-item-title">Vysoká kvalita a rychlost
                                                    stavby</h3>
                                                <div className="services-item-description">
                                                    <EditableText wrap={true} id={'why-viva.quality.description'} inputType={'wysiwyg'} fallback={
                                                        <p>Modulární domy se vyrábějí v suchém prostředí výrobní haly.
                                                            Výstavba není závislá na počasí, je rychlá a nákladově
                                                            efektivní. Do nového domu se budete moci stěhovat již za 3
                                                            měsíce. Díky průběžné kontrole je zajištěn vysoký standard
                                                            kvality v každém kroku stavby.</p>
                                                    }/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-item-wrapp prague_filter_class">
                                        <div className="portfolio-item">
                                            <div style={{height: "458px"}} className="prague-services-wrapper">
                                                {VivaIcons.grow}
                                                <h3 className="services-item-title">Obavy z růstu ceny?</h3>
                                                <div className="services-item-description">
                                                    <EditableText wrap={true} id={'why-viva.price.description'} inputType={'wysiwyg'} fallback={
                                                        <p>Při stavbě modulárního domu na vás nečekají žádná nemilá
                                                            překvapení v podobě nečekaných výdajů. Rozpočet je předem pevně
                                                            stanoven. Zaplatíte jen smluvně stanovenou cenu.</p>
                                                    }/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-item-wrapp prague_filter_class">
                                        <div className="portfolio-item">
                                            <div style={{height: "458px"}} className="prague-services-wrapper">
                                                {VivaIcons.arrows}

                                                <h3 className="services-item-title">Nekonečná variabilita</h3>
                                                <div className="services-item-description">
                                                    <EditableText wrap={true} id={'why-viva.variability.description'} inputType={'wysiwyg'} fallback={
                                                        <p>Potřebujete více prostoru? Máte-li dostatečně velký pozemek, k
                                                            vašemu domu je kdykoli možno přidat další moduly. Své bydlení
                                                            tak můžete vždy přizpůsobit svým představám.</p>
                                                    }/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-item-wrapp prague_filter_class column_paralax">
                                        <div className="portfolio-item">
                                            <div style={{height: "458px"}} className="prague-services-wrapper">
                                                {VivaIcons.smile}
                                                <h3 className="services-item-title">Zcela bez starostí</h3>
                                                <div className="services-item-description">
                                                    <EditableText wrap={true} id={'why-viva.no-worries.description'} inputType={'wysiwyg'} fallback={
                                                        <p>Nečeká na Vás žádný stres ohledně hledání dodavatelů. Modulární
                                                            dům navrhneme na základě vašich představ, vyrobíme a přivezeme
                                                            až k vám. Vy se budete muset jen nastěhovat.</p>
                                                    }/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-item-wrapp prague_filter_class">
                                        <div className="portfolio-item">
                                            <div style={{height: "458px"}} className="prague-services-wrapper">
                                                {VivaIcons.hand}

                                                <h3 className="services-item-title">Ekologické založení stavby</h3>
                                                <div className="services-item-description">
                                                    <EditableText wrap={true} id={'why-viva.eco.description'} inputType={'wysiwyg'} fallback={
                                                        <p>Založení modulární stavby se realizuje pomocí zemních vrutú,
                                                            betonových patek, což představuje značnou úsporu oproti betonové
                                                            základové desce. Terén musí být v rovině, aby jednotlivé moduly
                                                            do sebe zapadaly.</p>
                                                    }/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="kvalita" className="portfolio-item-wrapp prague_filter_class">
                                        <div className="portfolio-item">
                                            <div style={{height: "458px"}} className="prague-services-wrapper">
                                                {VivaIcons.like2}
                                                <EditableText wrapClassName={'position-relative'} wrap={true} id={'why-viva.why.description'} inputType={'wysiwyg'} fallback={
                                                    <>
                                                        <h1 className="services-item-title">TAK</h1>
                                                        <h1 className="services-item-title">PRO</h1>
                                                        <h1 className="services-item-title">TO !</h1>
                                                    </>
                                                }/>

                                                <div className="services-item-description">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container margin-lg-140b margin-xs-120b"></div>
    </div>
}

export default WhyViva