import React from "react";

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, dp = 2) => {
    const thresh = 1000;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

export const extensionToIcon = (extension?: string) => {
    let icon: [any, any]
    switch (extension) {
        case 'pdf':
            icon = ['far', 'file-pdf']
            break
        case 'xlsx':
            icon = ['far', 'file-excel']
            break
        case 'docx':
            icon = ['far', 'file-word']
            break
        case 'pptx':
            icon = ['far', 'file-powerpoint']
            break
        case 'py':
        case 'js':
        case 'css':
        case 'php':
        case 'xml':
            icon = ['far', 'file-code']
            break
        case 'mp4':
        case 'avi':
            icon = ['far', 'file-video']
            break
        default:
            icon = ['far', 'file']
    }
    return null
}

export const escapeDiacritic = (text: string, toLower: boolean = true) => {
    const escaped = text.normalize("NFD").replace(/\p{Diacritic}/gu, "")
    return toLower ? escaped.toLowerCase() : escaped
}

export const stringContains = (source: string, needle: string, escaped = true, toLower = true) => {
    return escapeDiacritic(source, toLower).indexOf(escapeDiacritic(needle, toLower)) >= 0
}

/**
 * Get uuid
 * @return {string}
 */
export  const uuid = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const decimalSeparator = () => {
    let n = 1.1;
    n = n.toLocaleString().substring(1, 2);
    return n;
}