import {Button, Col, Form, Image, Modal, Row, Select} from "antd";
import {SaveOutlined} from '@ant-design/icons'
import React, {FC, useState} from "react";
import {IModularHouse} from "../../../../api/entity/home/IModularHouse";
import ModularHouseService from "../../../../api/service/home/ModularHouseService";
import {uuid} from "../../../../shared/Utils";
import {IStore} from "../../../../redux/IStore";
import {connect} from "react-redux";
import moment from "moment";
import FileService from "../../../../api/service/FileService";

interface IProps {
    onFinish: (house?: IModularHouse) => void
    modularHouses: IModularHouse[]
}

const ModularCoverForm: FC<IProps> = ({onFinish, modularHouses}) => {

    const [form] = Form.useForm();

    const [saving, setSaving] = useState(false);

    const onSubmit = async (values: { house: string }) => {
        setSaving(true)
        const house = modularHouses.find(h => h.id === values.house)
        house && ModularHouseService.create({
            onHomepageCover: moment().unix(),
            id: house.id || uuid()
        }).then((result: IModularHouse) => {
            onFinish(result)
            setSaving(false)
        })
    }

    return <Modal title={'Upravit'} width={800} open={true} onCancel={() => onFinish()}
                  styles={{body: {maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden'}}}
                  classNames={{footer: 'border-top'}}
                  footer={[
                      <Row key={'row'} justify={"end"} className={'mt-2'}>
                          <Col>
                              <Button onClick={() => onFinish()} className={'mr-2'}>Zrušiť</Button>
                              <Button onClick={() => form.validateFields().then(onSubmit)} type={"primary"}
                                      icon={<SaveOutlined/>} loading={saving}>Uložiť</Button>
                          </Col>
                      </Row>
                  ]}>
        <Form layout={"vertical"} form={form} onFinish={onFinish}
              initialValues={{house: ModularHouseService.getHomepageCover([...modularHouses])?.id}} className={'w-100'}>
            <Row className={'mt-2 w-100 p-1'}>
                <Col className={'w-100'}>
                    <Form.Item label={'Dom'} name={'house'} className={'mb-1'}>
                        <Select showSearch={true} size={"large"} className={'w-100'} optionFilterProp={'title'}
                                options={modularHouses.filter(h => h.isPublic !== false).map(house => (
                                    {
                                        value: house.id, label: <Row justify={"space-between"}>
                                            <Col>
                                                {house.name}
                                            </Col>
                                            <Col>
                                                {house.images[0] && (
                                                    <Image height={30} preview={false} src={FileService.preview(house.images[0].uid)}/>
                                                )}
                                            </Col>
                                        </Row>,
                                        title: house.name
                                    }
                                ))}>

                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>
}

const mapStateToProps = ({setup}: IStore) => {
    const {modularHouses} = setup
    return {modularHouses}
}


export default connect(mapStateToProps)(ModularCoverForm);